import { useEffect, useRef, useState, ChangeEvent, MouseEvent } from "react";
import { Button, Spinner } from "react-bootstrap";
import Header from "../../components/Header";
import { getReqOpt_POST, runFetch, spURL_POST } from "../../functions/RunFetch";
import FilterAccordion from "../../components/FilterAccordion";
import ItemCardLarge from "../../components/ItemCardLarge";
import { IsArrayEmpty } from "../../functions/IsArrayEmpty";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { StringWithNoSpace } from "../../functions/StringWithNoSpace";
import { GetValueOfKey } from "../../functions/GetValueOfKey";
import MyOffcanvas from "../../components/MyOffcanvas";
import * as ExcelJS from "exceljs";
import * as FileSaver from "file-saver";
import CustomFilterPopup from "../../components/PopupCustomFilter";
import { useBeforeunload } from "react-beforeunload";

interface SourcingItemObj {
  ItemNo: string;
  ImgURL: string;
  Description: string;
  SupplierAccount: string;
  Manufacturer: string;
  ReorderNumber: string;
  Category: string;
  SubCategory: string;
  Segment: string;
  ItemPlanning: string;
  PrivateLabel: string;
  Seasonality: string;
  RangeStatus: string;
  ItemType: string;
  MarginType: string;
  LastPO: string;
  AvgCost: number;
  LastPOCost: number;
  Price: number;
  ForeignCost: number;
  MSRP: number;
  CloseoutPrice: number;
  MarginP: number;
  SellThrough: number;
  SelloutMarginP: number;
  Age: string;
  ReviewForecast: number;
  ReviewForecastRetail: number;
  TradingForecast: number;
  TradingForecastRetail: number;
  Forecast52W: number;
  Forecast52WRetail: number;
  QtySoldL7D: number;
  SOHQty: number;
  SOHRetail: number;
  CommittedQty: number;
  CommittedRetail: number;
  SOHStoreQty: number;
  SOHStoreRetail: number;
  SupplierHoldQty: number;
  SupplierHoldRetail: number;
  ShortMaxUnits: number;
  CatOTBAfterMonth: number;
  MarginDifference: number;
  Order: number;
  NewCommit: number;
  SoldLWQty: number;
  SoldLWRetail: number;
  SoldLMQty: number;
  SoldLMRetail: number;
  SoldYTDQty: number;
  SoldYTDRetail: number;
  SoldL52WQty: number;
  SoldL52WRetail: number;
  SoldLYQty: number;
  SoldLYRetail: number;
  SoldH2HQty: number;
  SoldH2HRetail: number;
  MaxInstockL4W: number;
  InstockLW: number;
  SOHWOC: number;
  CommittedWOC: number;
  RankLW: number;
  RankLM: number;
  RankYTD: number;
  RankLY: number;
  RankH2H: number;
  RankRRF: number;
  RankTAF: number;
  Rank52F: number;
  LeadCompName: string;
  LeadCompPrice: number;
  NPDStatus: string;
  NPDUnitsLW: number;
  NPDMarketShareL4W: number;
  NPDMarketShareL13W: number;
}

interface BuyerFilterObj {
  Buyer: string;
  Department: string;
  Category: string;
  SubCategory: string;
  Segment: string;
}

interface FilteredBuyerFilterObj {
  Buyer: string[];
  Department: string[];
  Category: string[];
  SubCategory: string[];
  Segment: string[];
}

interface ItemFilterObj {
  FieldName: string;
  Value: string;
}

interface SupplierFilterObj {
  SupplierGroup: string;
  Supplier: string;
}

interface MiscFilterObj {
  Manufacturer: string;
}

interface ItemDetailObj {
  Image: string;
  Link: string;
  "Subcat Order": number;
  "Sales Order": number;
  "Product Buy Type": string;
  Department: string;
  Category: string;
  SubCategory: string;
  "Supplier Buying Manager": string;
  Buyer: string;
  "Item Code": string;
  "Vendor Item Number": string;
  Description: string;
  Source: string;
  "Range Status": string;
  "Item Type": number;
  "Private Label": string;
  Supplier: string;
  Manufacturer: string;
  "Foreign Cost": string;
  "Last PO Cost": number;
  "Avg Cost": number;
  Price: number;
  "GP%": number;
  "Price Point": string;
  Grade: string;
  "No of Stores in Grade": number;
  "Effective Outer CBM": number;
  "Effective Outer Ctn Qty": number;
  "Ship Region": string;
  "Exchange Rate Used": string;
  "Shipping Rate Used": string;
  "New Item Foreign Cost": string;
  "New Landed Cost": string;
  "New Price": string;
  "New GP%": string;
  "Item Data Status": string;
  "Available Date": Date;
  "Item Planned Promotional Dates": string;
  "Total SOH Stores": number;
  "Total SOH Stores + DC": number;
  "Total SOO Stores + DC (Actual)": number;
  "Best WK Sales 52W": number;
  "Best WK Rolling 1 Year": number;
  "Range Review Forecast": number;
  "Trading Forecast": number;
  "LW Qty Sold": number;
  "L4W Qty Sold": number;
  "L13WK Qty Sold": number;
  "YTD Qty Sold": number;
  "LY H2H Qty Sold": number;
  "MAX L4W Instock%": number;
  "Total SOH Retail $": number;
  "Total SOO Retail $ (Actual)": number;
  "Total Committed $": number;
  "LW Retail $": number;
  "L4W Retail $": number;
  "L13WK Retail $": number;
  "YTD Retail $": number;
  "LY H2H Retail $": number;
  "Jan Buyer Hold": number;
  "Feb Buyer Hold": number;
  "Mar Buyer Hold": number;
  "Apr Buyer Hold": number;
  "May Buyer Hold": number;
  "Jun Buyer Hold": number;
  "Jul Buyer Hold": number;
  "Aug Buyer Hold": number;
  "Sep Buyer Hold": number;
  "Oct Buyer Hold": number;
  "Nov Buyer Hold": number;
  "Dec Buyer Hold": number;
  "Total Buyer Hold Qty": number;
  "Jan Buyer Cost $": number;
  "Feb Buyer Cost $": number;
  "Mar Buyer Cost $": number;
  "Apr Buyer Cost $": number;
  "May Buyer Cost $": number;
  "Jun Buyer Cost $": number;
  "Jul Buyer Cost $": number;
  "Aug Buyer Cost $": number;
  "Sep Buyer Cost $": number;
  "Oct Buyer Cost $": number;
  "Nov Buyer Cost $": number;
  "Dec Buyer Cost $": number;
  "Total Buyer Hold Cost $": number;
  "Jan Buyer Retail $": number;
  "Feb Buyer Retail $": number;
  "Mar Buyer Retail $": number;
  "Apr Buyer Retail $": number;
  "May Buyer Retail $": number;
  "Jun Buyer Retail $": number;
  "Jul Buyer Retail $": number;
  "Aug Buyer Retail $": number;
  "Sep Buyer Retail $": number;
  "Oct Buyer Retail $": number;
  "Nov Buyer Retail $": number;
  "Dec Buyer Retail $": number;
  "Total Buyer Hold Retail $": number;
  "Jan Planner Hold": number;
  "Feb Planner Hold": number;
  "Mar Planner Hold": number;
  "Apr Planner Hold": number;
  "May Planner Hold": number;
  "Jun Planner Hold": number;
  "Jul Planner Hold": number;
  "Aug Planner Hold": number;
  "Sep Planner Hold": number;
  "Oct Planner Hold": number;
  "Nov Planner Hold": number;
  "Dec Planner Hold": number;
  "Total Planner Hold Qty": number;
  "Jan Planner Cost $": number;
  "Feb Planner Cost $": number;
  "Mar Planner Cost $": number;
  "Apr Planner Cost $": number;
  "May Planner Cost $": number;
  "Jun Planner Cost $": number;
  "Jul Planner Cost $": number;
  "Aug Planner Cost $": number;
  "Sep Planner Cost $": number;
  "Oct Planner Cost $": number;
  "Nov Planner Cost $": number;
  "Dec Planner Cost $": number;
  "Total Planner Hold Cost $": number;
  "Jan Planner Retail $": number;
  "Feb Planner Retail $": number;
  "Mar Planner Retail $": number;
  "Apr Planner Retail $": number;
  "May Planner Retail $": number;
  "Jun Planner Retail $": number;
  "Jul Planner Retail $": number;
  "Aug Planner Retail $": number;
  "Sep Planner Retail $": number;
  "Oct Planner Retail $": number;
  "Nov Planner Retail $": number;
  "Dec Planner Retail $": number;
  "Total Planner Hold Retail $": number;
  "Jan Pre PO Qty": number;
  "Feb Pre PO Qty": number;
  "Mar Pre PO Qty": number;
  "Apr Pre PO Qty": number;
  "May Pre PO Qty": number;
  "Jun Pre PO Qty": number;
  "Jul Pre PO Qty": number;
  "Aug Pre PO Qty": number;
  "Sep Pre PO Qty": number;
  "Oct Pre PO Qty": number;
  "Nov Pre PO Qty": number;
  "Dec Pre PO Qty": number;
  "Total Pre PO Qty": number;
  "Jan Pre PO Cost $": number;
  "Feb Pre PO Cost $": number;
  "Mar Pre PO Cost $": number;
  "Apr Pre PO Cost $": number;
  "May Pre PO Cost $": number;
  "Jun Pre PO Cost $": number;
  "Jul Pre PO Cost $": number;
  "Aug Pre PO Cost $": number;
  "Sep Pre PO Cost $": number;
  "Oct Pre PO Cost $": number;
  "Nov Pre PO Cost $": number;
  "Dec Pre PO Cost $": number;
  "Total Pre PO Cost $": number;
  "Jan Pre PO Retail $": number;
  "Feb Pre PO Retail $": number;
  "Mar Pre PO Retail $": number;
  "Apr Pre PO Retail $": number;
  "May Pre PO Retail $": number;
  "Jun Pre PO Retail $": number;
  "Jul Pre PO Retail $": number;
  "Aug Pre PO Retail $": number;
  "Sep Pre PO Retail $": number;
  "Oct Pre PO Retail $": number;
  "Nov Pre PO Retail $": number;
  "Dec Pre PO Retail $": number;
  "Total Pre PO Retail $": number;
}

interface PriceChangeObj {
  ItemNo: string;
  ExchangeRate: string;
  ShippingRate: string;
  ForeignCost: string;
  LandedCost: string;
  Price: string;
}

interface HoldQtyObj {
  ItemNo: string;
  JanQty: string;
  FebQty: string;
  MarQty: string;
  AprQty: string;
  MayQty: string;
  JunQty: string;
  JulQty: string;
  AugQty: string;
  SepQty: string;
  OctQty: string;
  NovQty: string;
  DecQty: string;
}

function Planning() {
  useBeforeunload(() => {
    return "Are you sure to refresh this tab? Settings will be closed.";
  });

  const [Message, setMessage] = useState<string>("");
  const [SourcingItemList, setSourcingItemList] = useState<SourcingItemObj[]>([]);
  const [ItemFilterList, setItemFilterList] = useState<ItemFilterObj[]>([]);
  const [BuyerFilterList, setBuyerFilterList] = useState<BuyerFilterObj[]>([]);
  const [FilteredBuyerFilterList, setFilteredBuyerFilterList] = useState<FilteredBuyerFilterObj>({
    Buyer: [],
    Department: [],
    Category: [],
    SubCategory: [],
    Segment: [],
  });
  const [SupplierFilterList, setSupplierFilterList] = useState<SupplierFilterObj[]>([]);
  const [FilteredSupplierFilterList, setFilteredSupplierFilterList] = useState<SupplierFilterObj[]>(
    []
  );
  const [MiscFilterList, setMiscFilterList] = useState<MiscFilterObj[]>([]);
  const [SelectedItemPlanningList, setSelectedItemPlanningList] = useState<string[]>([]);
  const [SelectedItemTypeList, setSelectedItemTypeList] = useState<string[]>([]);
  const [SelectedItemSourceList, setSelectedItemSourceList] = useState<string[]>([]);
  const [SelectedRangeStatusList, setSelectedRangeStatusList] = useState<string[]>([]);
  const [SelectedMarginBandList, setSelectedMarginBandList] = useState<string[]>([]);
  const [SelectedPricePointList, setSelectedPricePointList] = useState<string[]>([]);
  const [SelectedAgeList, setSelectedAgeList] = useState<string[]>([]);
  const [SelectedCoverStatusSOHList, setSelectedCoverStatusSOHList] = useState<string[]>([]);
  const [SelectedCoverStatusCommittedList, setSelectedCoverStatusCommittedList] = useState<
    string[]
  >([]);
  const [SelectedPrivateLabelList, setSelectedPrivateLabelList] = useState<string[]>([]);
  const [SelectedSeasonalityList, setSelectedSeasonalityList] = useState<string[]>([]);
  const [SelectedCompetitorList, setSelectedCompetitorList] = useState<string[]>([]);
  const [SelectedBuyerList, setSelectedBuyerList] = useState<string[]>([]);
  const [SelectedDepartmentList, setSelectedDepartmentList] = useState<string[]>([]);
  const [SelectedCategoryList, setSelectedCategoryList] = useState<string[]>([]);
  const [SelectedSubCategoryList, setSelectedSubCategoryList] = useState<string[]>([]);
  const [SelectedSegmentList, setSelectedSegmentList] = useState<string[]>([]);
  const [SelectedSupplierGroupList, setSelectedSupplierGroupList] = useState<string[]>([]);
  const [SelectedSupplierList, setSelectedSupplierList] = useState<string[]>([]);
  const [SelectedManufacturerList, setSelectedManufacturerList] = useState<string[]>([]);
  const [SelectedCrossCategoryList, setSelectedCrossCategoryList] = useState<string[]>([]);
  const [SelectedBuyerPicksList, setSelectedBuyerPicksList] = useState<string[]>([]);
  const [SelectedMerchandisingTraitsList, setSelectedMerchandisingTraitsList] = useState<string[]>(
    []
  );
  const [SelectedPrivateLabelBrandList, setSelectedPrivateLabelBrandList] = useState<string[]>([]);
  const [SelectedNPDStatusList, setSelectedNPDStatusList] = useState<string[]>([]);
  const [SelectedNPDMarketShareList, setSelectedNPDMarketShareList] = useState<string[]>([]);
  const [SelectedInstockStatusList, setSelectedInstockStatusList] = useState<string[]>([]);
  const [SelectedSellThroughList, setSelectedSellThroughList] = useState<string[]>([]);
  const [SelectedRRForecastBandList, setSelectedRRForecastBandList] = useState<string[]>([]);
  const [SelectedTradingForecastBandList, setSelectedTradingForecastBandList] = useState<string[]>(
    []
  );
  const [SelectedH2HSalesBandList, setSelectedH2HSalesBandList] = useState<string[]>([]);

  const [SelectedItemList, setSelectedItemList] = useState<string[]>([]);

  const [PageNo, setPageNo] = useState<number>(1);

  const [sortColumn, setSortColumn] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");
  const [sortIcon, setSortIcon] = useState<IconDefinition>(solid("sort"));

  const [clearTriggered, setClearTriggered] = useState<boolean>(false);

  const [SearchDescription, setSearchDescription] = useState<string>("");
  const [Mode, setMode] = useState<string>("Range Review");

  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [showSort, setShowSort] = useState<boolean>(false);
  const [showCustomFilter, setShowCustomFilter] = useState<boolean>(false);
  const [enableCustomFilter, setEnableCustomFilter] = useState<boolean>(false);
  const [batchID, setBatchID] = useState<string>("");
  const [PlanningBatchID, setPlanningBatchID] = useState<number>(0);

  const [ProductView, setProductView] = useState<string>("large");

  const [IsGridLoading, setIsGridLoading] = useState(false);

  const inputFile = useRef<HTMLInputElement>(null);
  const importType = useRef<string>("");
  const MonthPast1 = useRef<string>("");
  const MonthPast2 = useRef<string>("");

  const getFilterList = async () => {
    const res = await runFetch(spURL_POST + "Portal_ItemList_Filter_Get", getReqOpt_POST("[{}]"));

    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      setMessage(resObj.exception);
    } else if (resObj.status === "OK!") {
      const spResponse: any = JSON.parse(resObj.response);
      //const spResponse: any = resObj.response;
      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setItemFilterList(
          spResponse.Table.map((item: ItemFilterObj) => ({
            FieldName: item.FieldName,
            Value: item.Value,
          }))
        );
      }

      if (spResponse.Table1 !== undefined && spResponse.Table1.length > 0) {
        setBuyerFilterList(
          spResponse.Table1.map((item: BuyerFilterObj) => ({
            Buyer: item.Buyer,
            Department: item.Department,
            Category: item.Category,
            SubCategory: item.SubCategory,
            Segment: item.Segment,
          }))
        );

        setFilteredBuyerFilterList({
          Buyer: getUniqueElementsOfArray(
            spResponse.Table1.map((item: BuyerFilterObj) => item.Buyer)
          ),
          Department: getUniqueElementsOfArray(
            spResponse.Table1.map((item: BuyerFilterObj) => item.Department)
          ),
          Category: getUniqueElementsOfArray(
            spResponse.Table1.map((item: BuyerFilterObj) => item.Category)
          ),
          SubCategory: getUniqueElementsOfArray(
            spResponse.Table1.map((item: BuyerFilterObj) => item.SubCategory)
          ),
          Segment: getUniqueElementsOfArray(
            spResponse.Table1.map((item: BuyerFilterObj) => item.Segment)
          ),
        });
      }
      if (spResponse.Table2 !== undefined && spResponse.Table2.length > 0) {
        setSupplierFilterList(
          spResponse.Table2.map((item: SupplierFilterObj) => ({
            SupplierGroup: item.SupplierGroup,
            Supplier: item.Supplier,
          }))
        );
        setFilteredSupplierFilterList(
          spResponse.Table2.map((item: SupplierFilterObj) => ({
            SupplierGroup: item.SupplierGroup,
            Supplier: item.Supplier,
          }))
        );
      }
      if (spResponse.Table3 !== undefined && spResponse.Table3.length > 0) {
        setMiscFilterList(
          spResponse.Table3.map((item: MiscFilterObj) => ({
            Manufacturer: item.Manufacturer,
          }))
        );
      }
    }
  };

  const getItems = async () => {
    setMessage("");
    setIsGridLoading(true);

    const inputJSON: string = JSON.stringify([
      { FilterName: "ITEM PLANNING", FilterValue: SelectedItemPlanningList },
      { FilterName: "ITEM SOURCE", FilterValue: SelectedItemSourceList },
      { FilterName: "ITEM TYPE", FilterValue: SelectedItemTypeList },
      { FilterName: "RANGE STATUS", FilterValue: SelectedRangeStatusList },
      { FilterName: "MARGIN BAND", FilterValue: SelectedMarginBandList },
      { FilterName: "PRICE POINT", FilterValue: SelectedPricePointList },
      { FilterName: "AGE", FilterValue: SelectedAgeList },
      { FilterName: "COVER STATUS SOH", FilterValue: SelectedCoverStatusSOHList },
      { FilterName: "COVER STATUS COMMITTED", FilterValue: SelectedCoverStatusCommittedList },
      { FilterName: "PRIVATE LABEL", FilterValue: SelectedPrivateLabelList },
      { FilterName: "SEASONALITY", FilterValue: SelectedSeasonalityList },
      { FilterName: "CROSS CATEGORY", FilterValue: SelectedCrossCategoryList },
      { FilterName: "COMPETITOR", FilterValue: SelectedCompetitorList },
      { FilterName: "BUYER PICKS", FilterValue: SelectedBuyerPicksList },
      { FilterName: "MERCHANDISING TRAITS", FilterValue: SelectedMerchandisingTraitsList },
      { FilterName: "PRIVATE LABEL BRAND", FilterValue: SelectedPrivateLabelBrandList },
      { FilterName: "NPD STATUS", FilterValue: SelectedNPDStatusList },
      { FilterName: "NPD MARKET SHARE", FilterValue: SelectedNPDMarketShareList },
      { FilterName: "INSTOCK STATUS", FilterValue: SelectedInstockStatusList },
      { FilterName: "SELL THROUGH", FilterValue: SelectedSellThroughList },
      { FilterName: "RR FORECAST BAND", FilterValue: SelectedRRForecastBandList },
      { FilterName: "TRADING FORECAST BAND", FilterValue: SelectedTradingForecastBandList },
      { FilterName: "H2H SALES BAND", FilterValue: SelectedH2HSalesBandList },
      { FilterName: "BUYER", FilterValue: SelectedBuyerList },
      { FilterName: "DEPARTMENT", FilterValue: SelectedDepartmentList },
      { FilterName: "CATEGORY", FilterValue: SelectedCategoryList },
      { FilterName: "SUBCATEGORY", FilterValue: SelectedSubCategoryList },
      { FilterName: "SEGMENT", FilterValue: SelectedSegmentList },
      { FilterName: "SUPPLIER GROUP", FilterValue: SelectedSupplierGroupList },
      { FilterName: "SUPPLIER", FilterValue: SelectedSupplierList },
      { FilterName: "MANUFACTURER", FilterValue: SelectedManufacturerList },
      { FilterName: "SEARCH", FilterValue: SearchDescription },
      { FilterName: "PAGE", FilterValue: PageNo },
      { FilterName: "SORT", FilterValue: sortColumn + "-" + sortOrder },
      { FilterName: "CUSTOM FILTER", FilterValue: batchID },
    ]);

    const res = await runFetch(
      spURL_POST + "Portal_PlanningItemList_Get",
      getReqOpt_POST(inputJSON)
    );

    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      setMessage(resObj.exception);
    } else if (resObj.status === "OK!") {
      // response === "" means no data found in table
      if (resObj.response === "") {
        setSourcingItemList([]);
      } else {
        const spResponse: any = JSON.parse(resObj.response);
        //const spResponse: any = resObj.response;

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          setSourcingItemList(
            spResponse.Table.map((item: SourcingItemObj) => ({
              ItemNo: item.ItemNo,
              ImgURL: item.ImgURL,
              Description: item.Description,
              SupplierAccount: item.SupplierAccount,
              Manufacturer: item.Manufacturer,
              ReorderNumber: item.ReorderNumber,
              Category: item.Category,
              SubCategory: item.SubCategory,
              Segment: item.Segment,
              ItemPlanning: item.ItemPlanning,
              PrivateLabel: item.PrivateLabel,
              Seasonality: item.Seasonality,
              RangeStatus: item.RangeStatus,
              ItemType: item.ItemType,
              MarginType: item.MarginType,
              LastPO: item.LastPO,
              AvgCost: item.AvgCost,
              LastPOCost: item.LastPOCost,
              Price: item.Price,
              ForeignCost: item.ForeignCost,
              MSRP: item.MSRP,
              CloseoutPrice: item.CloseoutPrice,
              MarginP: item.MarginP,
              SellThrough: item.SellThrough,
              SelloutMarginP: item.SelloutMarginP,
              Age: item.Age,
              ReviewForecast: item.ReviewForecast,
              ReviewForecastRetail: item.ReviewForecastRetail,
              TradingForecast: item.TradingForecast,
              TradingForecastRetail: item.TradingForecastRetail,
              Forecast52W: item.Forecast52W,
              Forecast52WRetail: item.Forecast52WRetail,
              QtySoldL7D: item.QtySoldL7D,
              SOHQty: item.SOHQty,
              SOHRetail: item.SOHRetail,
              CommittedQty: item.CommittedQty,
              CommittedRetail: item.CommittedRetail,
              SOHStoreQty: item.SOHStoreQty,
              SOHStoreRetail: item.SOHStoreRetail,
              SupplierHoldQty: item.SupplierHoldQty,
              SupplierHoldRetail: item.SupplierHoldRetail,
              ShortMaxUnits: item.ShortMaxUnits,
              CatOTBAfterMonth: item.CatOTBAfterMonth,
              MarginDifference: item.MarginDifference,
              Order: item.Order,
              NewCommit: item.NewCommit,
              SoldLWQty: item.SoldLWQty,
              SoldLWRetail: item.SoldLWRetail,
              SoldLMQty: item.SoldLMQty,
              SoldLMRetail: item.SoldLMRetail,
              SoldYTDQty: item.SoldYTDQty,
              SoldYTDRetail: item.SoldYTDRetail,
              SoldL52WQty: item.SoldL52WQty,
              SoldL52WRetail: item.SoldL52WRetail,
              SoldLYQty: item.SoldLYQty,
              SoldLYRetail: item.SoldLYRetail,
              SoldH2HQty: item.SoldH2HQty,
              SoldH2HRetail: item.SoldH2HRetail,
              MaxInstockL4W: item.MaxInstockL4W,
              InstockLW: item.InstockLW,
              SOHWOC: item.SOHWOC,
              CommittedWOC: item.CommittedWOC,
              RankLW: item.RankLW,
              RankLM: item.RankLM,
              RankYTD: item.RankYTD,
              RankLY: item.RankLY,
              RankH2H: item.RankH2H,
              RankRRF: item.RankRRF,
              RankTAF: item.RankTAF,
              Rank52F: item.Rank52F,
              LeadCompName: item.LeadCompName,
              LeadCompPrice: item.LeadCompPrice,
              NPDStatus: item.NPDStatus,
              NPDUnitsLW: item.NPDUnitsLW,
              NPDMarketShareL4W: item.NPDMarketShareL4W,
              NPDMarketShareL13W: item.NPDMarketShareL13W,
            }))
          );
        }
        if (spResponse.Table1 !== undefined && spResponse.Table1.length > 0) {
          setPlanningBatchID(spResponse.Table1[0].BatchID);
        }
      }
    }

    setIsGridLoading(false);
  };

  const onFilterItemListChange = (setFunction: Function, list: string[]) => {
    setFunction(list);
    setPageNo(1);
  };

  const getUniqueElementsOfArray = (arr: any[]) => {
    return arr.filter(function (value, index, array) {
      return array.indexOf(value) === index;
    });
  };

  const getItemDetail = async () => {
    let itemDetailToReturn: ItemDetailObj[] = [];

    const inputJSON: string = JSON.stringify({
      BatchID: PlanningBatchID,
    });

    const res = await runFetch(
      spURL_POST + "Portal_Planning_ItemDetail",
      getReqOpt_POST(inputJSON)
    );

    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      setMessage(resObj.exception);
    } else if (resObj.status === "OK!") {
      // response === "" means no data found in table
      if (resObj.response === "") {
      } else {
        const spResponse: any = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          itemDetailToReturn = spResponse.Table.map((item: ItemDetailObj) => ({
            ...item,
          }));
        }
        if (spResponse.Table1 !== undefined && spResponse.Table1.length > 0) {
          MonthPast1.current = spResponse.Table1[0].Month1;
          MonthPast2.current = spResponse.Table1[0].Month2;
        }
      }
    }
    return itemDetailToReturn;
  };

  function getFilteredBuyerFilterList() {
    // buyer always show so we just show all buyers
    let buyerList = getUniqueElementsOfArray(BuyerFilterList.map((item) => item.Buyer));

    // get departments attached to the buyers selected
    let departmentList = getUniqueElementsOfArray(
      BuyerFilterList.map((item) => ({
        Buyer: item.Buyer,
        Department: item.Department,
      }))
        .filter((item) => SelectedBuyerList.length === 0 || SelectedBuyerList.includes(item.Buyer))
        .map((item) => item.Department)
    );

    // get categories attached to the departments selected
    let categoryList = getUniqueElementsOfArray(
      BuyerFilterList.map((item) => ({
        Department: item.Department,
        Category: item.Category,
      }))
        .filter(
          (item) =>
            SelectedDepartmentList.length === 0 || SelectedDepartmentList.includes(item.Department)
        )
        .map((item) => item.Category)
    );

    // get subcategories attached to the categories selected
    let subCategoryList = getUniqueElementsOfArray(
      BuyerFilterList.map((item) => ({
        Category: item.Category,
        SubCategory: item.SubCategory,
      }))
        .filter(
          (item) =>
            SelectedCategoryList.length === 0 || SelectedCategoryList.includes(item.Category)
        )
        .map((item) => item.SubCategory)
    );

    // get segments attached to the subcategories selected
    let segmentList = getUniqueElementsOfArray(
      BuyerFilterList.map((item) => ({
        SubCategory: item.SubCategory,
        Segment: item.Segment,
      }))
        .filter(
          (item) =>
            SelectedSubCategoryList.length === 0 ||
            SelectedSubCategoryList.includes(item.SubCategory)
        )
        .map((item) => item.Segment)
    );

    setFilteredBuyerFilterList({
      Buyer: buyerList,
      Department: departmentList,
      Category: categoryList,
      SubCategory: subCategoryList,
      Segment: segmentList,
    });
  }

  function getFilteredSupplierFilterList() {
    let NewList = SupplierFilterList.filter(
      (item) =>
        (IsArrayEmpty(SelectedSupplierGroupList) ||
          SelectedSupplierGroupList.includes(item.SupplierGroup)) &&
        (IsArrayEmpty(SelectedSupplierList) || SelectedSupplierList.includes(item.Supplier))
    );

    setFilteredSupplierFilterList(NewList);
  }

  const handleClick_Apply = () => {
    setPageNo(1);
    getFilteredBuyerFilterList();
    getFilteredSupplierFilterList();
  };

  const refreshFilter = () => {
    getFilterList();

    const checkboxes = document.getElementsByClassName("form-check-input");

    for (let i = 0; i < checkboxes.length; i++) {
      const checkbox = checkboxes[i] as HTMLInputElement;
      checkbox.checked = false;
    }

    setSelectedItemPlanningList([]);
    setSelectedItemSourceList([]);
    setSelectedItemTypeList([]);
    setSelectedRangeStatusList([]);
    setSelectedMarginBandList([]);
    setSelectedPricePointList([]);
    setSelectedAgeList([]);
    setSelectedCoverStatusSOHList([]);
    setSelectedCoverStatusCommittedList([]);
    setSelectedPrivateLabelList([]);
    setSelectedSeasonalityList([]);
    setSelectedCompetitorList([]);
    setSelectedBuyerList([]);
    setSelectedDepartmentList([]);
    setSelectedCategoryList([]);
    setSelectedSubCategoryList([]);
    setSelectedSegmentList([]);
    setSelectedSupplierGroupList([]);
    setSelectedSupplierList([]);
    setSelectedManufacturerList([]);
    setSelectedItemList([]);
    setSelectedCrossCategoryList([]);
    setSelectedBuyerPicksList([]);
    setSelectedMerchandisingTraitsList([]);
    setSelectedPrivateLabelBrandList([]);
    setSelectedNPDStatusList([]);
    setSelectedNPDMarketShareList([]);
    setSelectedInstockStatusList([]);
    setSelectedSellThroughList([]);
    setSelectedRRForecastBandList([]);
    setSelectedTradingForecastBandList([]);
    setSelectedH2HSalesBandList([]);
  };

  const handleClick_Clear = () => {
    setBatchID("");
    refreshFilter();
    handleClick_Apply();
    setClearTriggered(!clearTriggered);
    setEnableCustomFilter(false);
  };

  const handleClick_CustomFilter = () => {
    setShowCustomFilter(!showCustomFilter);
  };

  const handleClick_LoadMore = () => {
    setPageNo(PageNo + 1);
  };

  const renderItemFilters = () => {
    const itemFilters = [
      { name: "Item Planning", selectFunction: setSelectedItemPlanningList },
      { name: "Item Source", selectFunction: setSelectedItemSourceList },
      { name: "Item Type", selectFunction: setSelectedItemTypeList },
      { name: "Range Status", selectFunction: setSelectedRangeStatusList },
      { name: "Margin Band", selectFunction: setSelectedMarginBandList },
      { name: "Price Point", selectFunction: setSelectedPricePointList },
      { name: "Age", selectFunction: setSelectedAgeList },
      { name: "CoverStatus SOH", selectFunction: setSelectedCoverStatusSOHList },
      { name: "CoverStatus Committed", selectFunction: setSelectedCoverStatusCommittedList },
      { name: "Private Label", selectFunction: setSelectedPrivateLabelList },
      { name: "Seasonality", selectFunction: setSelectedSeasonalityList },
      { name: "Cross Category", selectFunction: setSelectedCrossCategoryList },
      { name: "Competitor", selectFunction: setSelectedCompetitorList },
      { name: "Buyer Picks", selectFunction: setSelectedBuyerPicksList },
      { name: "Merchandising Traits", selectFunction: setSelectedMerchandisingTraitsList },
      { name: "Private Label Brand", selectFunction: setSelectedPrivateLabelBrandList },
      { name: "NPD Status", selectFunction: setSelectedNPDStatusList },
      { name: "NPD Market Share", selectFunction: setSelectedNPDMarketShareList },
      { name: "Instock Status", selectFunction: setSelectedInstockStatusList },
      { name: "Sell Through", selectFunction: setSelectedSellThroughList },
      { name: "RR Forecast Band", selectFunction: setSelectedRRForecastBandList },
      { name: "Trading Forecast Band", selectFunction: setSelectedTradingForecastBandList },
      { name: "H2H Sales Band", selectFunction: setSelectedH2HSalesBandList },
    ];

    return itemFilters.map((filter, index) => (
      <FilterAccordion
        key={index}
        FilterName={filter.name}
        Items={getUniqueElementsOfArray(
          ItemFilterList.filter((item) => item.FieldName === StringWithNoSpace(filter.name)).map(
            (item) => item.Value
          )
        )}
        FilterItems={getUniqueElementsOfArray(
          ItemFilterList.filter((item) => item.FieldName === StringWithNoSpace(filter.name)).map(
            (item) => item.Value
          )
        )}
        OnFilterChange={(list) => onFilterItemListChange(filter.selectFunction, list)}
        clearTriggered={clearTriggered}
      ></FilterAccordion>
    ));
  };

  const renderBuyerFilters = () => {
    const buyerFilters = [
      { name: "Buyer", selectFunction: setSelectedBuyerList },
      { name: "Department", selectFunction: setSelectedDepartmentList },
      { name: "Category", selectFunction: setSelectedCategoryList },
      { name: "SubCategory", selectFunction: setSelectedSubCategoryList },
      { name: "Segment", selectFunction: setSelectedSegmentList },
    ];

    return buyerFilters.map((filter, index) => (
      <FilterAccordion
        key={index}
        FilterName={filter.name}
        Items={getUniqueElementsOfArray(
          BuyerFilterList.map((item) => GetValueOfKey(item, filter.name))
        )}
        FilterItems={GetValueOfKey(FilteredBuyerFilterList, filter.name)}
        OnFilterChange={(list) => onFilterItemListChange(filter.selectFunction, list)}
        clearTriggered={clearTriggered}
      ></FilterAccordion>
    ));
  };

  const renderSupplierFilters = () => {
    const supplierFilters = [
      { name: "Supplier Group", selectFunction: setSelectedSupplierGroupList },
      { name: "Supplier", selectFunction: setSelectedSupplierList },
    ];

    return supplierFilters.map((filter, index) => (
      <FilterAccordion
        key={index}
        FilterName={filter.name}
        Items={getUniqueElementsOfArray(
          SupplierFilterList.map((item) => GetValueOfKey(item, StringWithNoSpace(filter.name)))
        )}
        FilterItems={getUniqueElementsOfArray(
          FilteredSupplierFilterList.map((item) =>
            GetValueOfKey(item, StringWithNoSpace(filter.name))
          )
        )}
        OnFilterChange={(list) => onFilterItemListChange(filter.selectFunction, list)}
        clearTriggered={clearTriggered}
      ></FilterAccordion>
    ));
  };

  const renderMiscFilters = () => {
    const itemFilters = [{ name: "Manufacturer", selectFunction: setSelectedManufacturerList }];

    return itemFilters.map((filter, index) => (
      <FilterAccordion
        key={index}
        FilterName={filter.name}
        Items={getUniqueElementsOfArray(
          MiscFilterList.map((item) => GetValueOfKey(item, filter.name))
        )}
        FilterItems={getUniqueElementsOfArray(
          MiscFilterList.map((item) => GetValueOfKey(item, filter.name))
        )}
        OnFilterChange={(list) => onFilterItemListChange(filter.selectFunction, list)}
        clearTriggered={clearTriggered}
      ></FilterAccordion>
    ));
  };

  const renderSort = () => {
    const sortArr = [
      { Title: "Supplier Account", SortColumn: "SupplierAccount", SortOrder: "ASC" },
      { Title: "Sell Price: Low to High", SortColumn: "SellPrice", SortOrder: "ASC" },
      { Title: "Sell Price: High to Low", SortColumn: "SellPrice", SortOrder: "DESC" },
      { Title: "Last PO Cost", SortColumn: "LastPOCost", SortOrder: "ASC" },
      { Title: "Cost Price", SortColumn: "CostPrice", SortOrder: "ASC" },
      { Title: "Qty Sold L7D", SortColumn: "QtySoldL7D", SortOrder: "DESC" },
      { Title: "Qty Sold L52W", SortColumn: "QtySoldL52W", SortOrder: "DESC" },
      { Title: "Sold$ LW", SortColumn: "SoldLWD", SortOrder: "DESC" },
      { Title: "Sold$ LM", SortColumn: "SoldLMD", SortOrder: "DESC" },
      { Title: "Sold$ YTD", SortColumn: "SoldYTD", SortOrder: "DESC" },
      { Title: "Sold$ L52W", SortColumn: "SoldL52W", SortOrder: "DESC" },
      { Title: "Sold$ LY H2H", SortColumn: "LYH2H", SortOrder: "DESC" },
      { Title: "RR Forecast Retail", SortColumn: "RRForecastRetail", SortOrder: "DESC" },
      { Title: "Trading Forecast Retail", SortColumn: "TradingForecastRetail", SortOrder: "DESC" },
      { Title: "52W Forecast Retail", SortColumn: "52WForecastRetail", SortOrder: "DESC" },
      { Title: "RR Forecast: Low to High", SortColumn: "RRForecast", SortOrder: "ASC" },
      { Title: "RR Forecast: High to Low", SortColumn: "RRForecast", SortOrder: "DESC" },
      { Title: "Supplier Hold Retail", SortColumn: "SupplierHoldRetail", SortOrder: "DESC" },
      { Title: "Committed SOH $", SortColumn: "CommittedRetail", SortOrder: "DESC" },
      { Title: "Max Instock% L4W: Low to High", SortColumn: "MaxInstockL4W", SortOrder: "ASC" },
      { Title: "Max Instock% L4W: High to Low", SortColumn: "MaxInstockL4W", SortOrder: "DESC" },
      { Title: "Committed WOC: Low to High", SortColumn: "CommittedWOC", SortOrder: "ASC" },
      { Title: "Committed WOC: High to Low", SortColumn: "CommittedWOC", SortOrder: "DESC" },
    ];

    return sortArr.map((sortObj, index) => (
      <li
        key={index}
        className={`${
          sortObj.SortColumn + "-" + sortObj.SortOrder === sortColumn + "-" + sortOrder &&
          "selected"
        } btnHover`}
        data-sortcolumn={sortObj.SortColumn}
        data-sortorder={sortObj.SortOrder}
        onClick={() => {
          setSortColumn(sortObj.SortColumn);
          setSortOrder(sortObj.SortOrder);
          setShowSort(false); // hide sort offcanvas after selecting
        }}
      >
        {sortObj.Title}
      </li>
    ));
  };

  const renderItems = () => {
    let ProductDisplay;

    ProductDisplay = (
      <div className="card-flexbox">
        {SourcingItemList.map((item, index) => (
          <div className={"card-large-wrapper"} onClick={() => {}}>
            <ItemCardLarge
              key={index}
              {...item}
              Mode={Mode}
              IsSelected={SelectedItemList.includes(item.ItemNo)}
              ToggleCardSelect={() => {}}
              PriceChangeType=" "
              NewSellPrice={0}
              BuyerComment=" "
              Approved={0}
              DateJSON=""
              WeekOfYear={0}
            />
          </div>
        ))}
      </div>
    );
    return ProductDisplay;
  };

  const handleClick_Search = () => {
    setShowSearch(!showSearch);
  };

  const handleClick_Filter = () => {
    setShowFilter(true);
  };

  const handleClick_Sort = () => {
    setShowSort(true);
  };

  const handleClick_SelectAll = () => {
    const arrAllItems: string[] = SourcingItemList.map((item) => item.ItemNo);
    setSelectedItemList(arrAllItems);
  };

  const handleClick_PlanningReport = async () => {
    setIsGridLoading(true);

    var workbook = new ExcelJS.Workbook();

    var sheet0 = workbook.addWorksheet("Item Detail", {
      views: [{ activeCell: "A1", showGridLines: true }],
    });

    //Item Detail
    if (sheet0 !== undefined) {
      const itemDetailArr: ItemDetailObj[] = await getItemDetail();

      if (itemDetailArr.length > 0) {
        let firstItemRowObj: ItemDetailObj = itemDetailArr[0];

        //sheet0.properties.defaultRowHeight = 30;
        sheet0.properties.defaultColWidth = 15;

        // set header information
        sheet0.columns = Object.keys(firstItemRowObj).map((key) => ({
          header: key,
          key: key,
        }));

        // add data
        sheet0.addRows(itemDetailArr, "i");
        sheet0.getCell("A2").value = '=@IMAGE(B2,"Image Not Found",0)';
        sheet0.getColumn(2).hidden = true;

        sheet0.getRow(1).alignment = { wrapText: true };
        sheet0.getRow(1).height = 45;

        //////FORMATTING//////
        let totalColumns = sheet0.actualColumnCount;
        let N0array: number[] = [];
        let BuyerQtyArray = [63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74];
        let BuyerCostArray = [76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87];
        let BuyerRetailArray = [89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100];
        let PlannerQtyArray = [102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113];
        let PlannerCostArray = [115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126];
        let PlannerRetailArray = [128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139];
        let PrePOQtyArray = [141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152];
        let PrePOCostArray = [154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165];
        let PrePORetailArray = [167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178];

        N0array.push(...BuyerQtyArray);
        N0array.push(...PlannerQtyArray);
        N0array.push(...PrePOQtyArray);

        //Set Past 2 months in red so buyers know
        for (let i = 1; i <= totalColumns; i++) {
          if (
            sheet0.getCell(1, i).value?.toString().includes(MonthPast1.current) ||
            sheet0.getCell(1, i).value?.toString().includes(MonthPast2.current)
          ) {
            sheet0.getCell(1, i).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFCD5C5C" },
            };
          }
        }

        //Foreign Cost
        sheet0.getColumn("Foreign Cost").numFmt = '"$"#,##0.00';
        //Last PO Cost
        sheet0.getColumn("Last PO Cost").numFmt = '"$"#,##0.00';
        //Avg Cost
        sheet0.getColumn("Avg Cost").numFmt = '"$"#,##0.00';
        //Price
        sheet0.getColumn("Price").numFmt = '"$"#,##0.00';
        //GP%
        sheet0.getColumn("GP%").numFmt = "0.0%";
        //Effective Outer CBM
        sheet0.getColumn("Effective Outer CBM").numFmt = "0.0000";
        //Effective Outer ctn Qty
        sheet0.getColumn("Effective Outer Ctn Qty").numFmt = "#,##0";
        //Shipping Rate Used
        sheet0.getColumn("Shipping Rate Used").numFmt = '"$"#,##0.00';
        //New Item Foregin Cost
        sheet0.getColumn("New Item Foreign Cost").numFmt = '"$"#,##0.00';
        //New Landed Cost
        sheet0.getColumn("New Landed Cost").numFmt = '"$"#,##0.00';
        //New Price
        sheet0.getColumn("New Price").numFmt = '"$"#,##0.00';
        //New GP%
        sheet0.getColumn("New GP%").numFmt = "0.0%";
        //Available Date
        sheet0.getColumn("Available Date").numFmt = "dd/mm/yyyy";
        // Total SOH Stores
        sheet0.getColumn("Total SOH Stores").numFmt = "#,##0";
        // Total SOH Stores + DC
        sheet0.getColumn("Total SOH Stores + DC").numFmt = "#,##0";
        // Total SOO Stores + DC (Actual)
        sheet0.getColumn("Total SOO Stores + DC (Actual)").numFmt = "#,##0";
        // Best WK Sales 52W
        sheet0.getColumn("Best WK Sales 52W").numFmt = "#,##0";
        // Best WK Rolling 1 Year
        sheet0.getColumn("Best WK Rolling 1 Year").numFmt = "#,##0";
        // Range Review Forecast
        sheet0.getColumn("Range Review Forecast").numFmt = "#,##0";
        // Trading Forecast
        sheet0.getColumn("Trading Forecast").numFmt = "#,##0";
        // LW Qty Sold
        sheet0.getColumn("LW Qty Sold").numFmt = "#,##0";
        // L4W Qty Sold
        sheet0.getColumn("L4W Qty Sold").numFmt = "#,##0";
        // L13W Qty Sold
        sheet0.getColumn("L13W Qty Sold").numFmt = "#,##0";
        // YTD Qty Sold
        sheet0.getColumn("YTD Qty Sold").numFmt = "#,##0";
        // LY H2H Qty Sold
        sheet0.getColumn("LY H2H Qty Sold").numFmt = "#,##0";
        // L52W Qty Sold
        sheet0.getColumn("L52W Qty Sold").numFmt = "#,##0";
        // MAX L4W Instock%
        sheet0.getColumn("MAX L4W Instock%").numFmt = "0.0%";
        // Total SOH Retail$
        sheet0.getColumn("Total SOH Retail$").numFmt = '"$"#,##0';
        // Total SOO Retail $ (Actual)
        sheet0.getColumn("Total SOO Retail $ (Actual)").numFmt = '"$"#,##0';
        // Total Committed $
        sheet0.getColumn("Total Committed $").numFmt = '"$"#,##0';
        // LW Retail $
        sheet0.getColumn("LW Retail $").numFmt = '"$"#,##0';
        // L4W Retail $
        sheet0.getColumn("L4W Retail $").numFmt = '"$"#,##0';
        // L13W Retail $
        sheet0.getColumn("L13W Retail $").numFmt = '"$"#,##0';
        // YTD Retail $
        sheet0.getColumn("YTD Retail $").numFmt = '"$"#,##0';
        // LY H2H Retail $
        sheet0.getColumn("LY H2H Retail $").numFmt = '"$"#,##0';
        // LY L52W Retail $
        sheet0.getColumn("LY L52W Retail $").numFmt = '"$"#,##0';
        // Total Buyer Hold Qty
        sheet0.getColumn("Total Buyer Hold Qty").numFmt = "#,##0";
        // Total Buyer Hold Cost $
        sheet0.getColumn("Total Buyer Hold Cost $").numFmt = '"$"#,##0';
        // Total Buyer Hold Retail $
        sheet0.getColumn("Total Buyer Hold Retail $").numFmt = '"$"#,##0';
        // Total Planner Hold Qty
        sheet0.getColumn("Total Planner Hold Qty").numFmt = "#,##0";
        // Total Planner Hold Cost $
        sheet0.getColumn("Total Planner Hold Cost $").numFmt = '"$"#,##0';
        // Total Planner Hold Retail $
        sheet0.getColumn("Total Planner Hold Retail $").numFmt = '"$"#,##0';
        // Total Pre PO Qty
        sheet0.getColumn("Total Pre PO Qty").numFmt = "#,##0";
        // Total Pre PO Cost $
        sheet0.getColumn("Total Pre PO Cost $").numFmt = '"$"#,##0';
        // Total Pre PO Retail $
        sheet0.getColumn("Total Pre PO Retail $").numFmt = '"$"#,##0';

        for (let idx in N0array) {
          sheet0.getColumn(N0array[idx]).numFmt = "#,##0";
        }

        //////FORMULAS//////

        for (let i = 2; i <= itemDetailArr.length + 1; i++) {
          //New landed cost
          sheet0.getCell(`AH${i}`).value = { formula: `=AG${i}*AE${i}+((AB${i}/AC${i})*AF${i})` };
          sheet0.getCell(`AH${i}`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFD8D8D8" },
          };

          //New GP %
          sheet0.getCell(`AJ${i}`).value = { formula: `=((AI${i}/1.1)-AH${i})/(AI${i}/1.1)` };
          sheet0.getCell(`AJ${i}`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFD8D8D8" },
          };
          //Total Buyer Hold Qty
          sheet0.getCell(`BW${i}`).value = { formula: `=SUM(BK${i}:BV${i})` };
          sheet0.getCell(`BW${i}`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFD8D8D8" },
          };
          //Buyer Cost
          for (let idx in BuyerCostArray) {
            const monthQuantity = sheet0.getCell(i, BuyerCostArray[idx] - 13).model.address;
            const currentCell = sheet0.getCell(i, BuyerCostArray[idx]).model.address;
            const formula = `=${monthQuantity}*AH${i}`;
            sheet0.getCell(`${currentCell}`).value = { formula: formula };

            sheet0.getCell(`${currentCell}`).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFD8D8D8" },
            };

            sheet0.getCell(`${currentCell}`).numFmt = '"$"#,##0';
          }
          //Buyer Retail
          for (let idx in BuyerRetailArray) {
            const monthQuantity = sheet0.getCell(i, BuyerRetailArray[idx] - 26).model.address;
            const currentCell = sheet0.getCell(i, BuyerRetailArray[idx]).model.address;
            const formula = `=${monthQuantity}*AI${i}`;
            sheet0.getCell(`${currentCell}`).value = { formula: formula };

            sheet0.getCell(`${currentCell}`).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFD8D8D8" },
            };

            sheet0.getCell(`${currentCell}`).numFmt = '"$"#,##0';
          }
          //Total Buyer Hold Cost
          sheet0.getCell(`CJ${i}`).value = { formula: `=SUM(BX${i}:CI${i})` };
          sheet0.getCell(`CJ${i}`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFD8D8D8" },
          };
          //Total Buyer Hold Retail
          sheet0.getCell(`CW${i}`).value = { formula: `=SUM(CK${i}:CV${i})` };
          sheet0.getCell(`CW${i}`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFD8D8D8" },
          };
          //Total Planner Hold Qty
          sheet0.getCell(`DJ${i}`).value = { formula: `=SUM(CX${i}:DI${i})` };
          sheet0.getCell(`DJ${i}`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFD8D8D8" },
          };
          //Planner Cost
          for (let idx in PlannerCostArray) {
            const monthQuantity = sheet0.getCell(i, PlannerCostArray[idx] - 13).model.address;
            const currentCell = sheet0.getCell(i, PlannerCostArray[idx]).model.address;
            const formula = `=${monthQuantity}*AH${i}`;
            sheet0.getCell(`${currentCell}`).value = { formula: formula };
            sheet0.getCell(`${currentCell}`).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFD8D8D8" },
            };

            sheet0.getCell(`${currentCell}`).numFmt = '"$"#,##0';
          }
          //Planner Retail
          for (let idx in PlannerRetailArray) {
            const monthQuantity = sheet0.getCell(i, PlannerRetailArray[idx] - 26).model.address;
            const currentCell = sheet0.getCell(i, PlannerRetailArray[idx]).model.address;
            const formula = `=${monthQuantity}*AI${i}`;
            sheet0.getCell(`${currentCell}`).value = { formula: formula };
            sheet0.getCell(`${currentCell}`).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFD8D8D8" },
            };

            sheet0.getCell(`${currentCell}`).numFmt = '"$"#,##0';
          }
          //Total Planner Hold Cost
          sheet0.getCell(`DW${i}`).value = { formula: `=SUM(DK${i}:DV${i})` };
          sheet0.getCell(`DW${i}`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFD8D8D8" },
          };
          //Total Planner Hold Retail
          sheet0.getCell(`EJ${i}`).value = { formula: `=SUM(DX${i}:EI${i})` };
          sheet0.getCell(`EJ${i}`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFD8D8D8" },
          };

          //Total PrePO Qty
          sheet0.getCell(`EW${i}`).value = { formula: `=SUM(EK${i}:EV${i})` };
          sheet0.getCell(`EW${i}`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFD8D8D8" },
          };

          //PrePO Cost
          for (let idx in PrePOCostArray) {
            const monthQuantity = sheet0.getCell(i, PrePOCostArray[idx] - 13).model.address;
            const currentCell = sheet0.getCell(i, PrePOCostArray[idx]).model.address;
            const formula = `=${monthQuantity}*AH${i}`;
            sheet0.getCell(`${currentCell}`).value = { formula: formula };
            sheet0.getCell(`${currentCell}`).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFD8D8D8" },
            };

            sheet0.getCell(`${currentCell}`).numFmt = '"$"#,##0';
          }
          //PrePO Retail
          for (let idx in PrePORetailArray) {
            const monthQuantity = sheet0.getCell(i, PrePORetailArray[idx] - 26).model.address;
            const currentCell = sheet0.getCell(i, PrePORetailArray[idx]).model.address;
            const formula = `=${monthQuantity}*AI${i}`;
            sheet0.getCell(`${currentCell}`).value = { formula: formula };
            sheet0.getCell(`${currentCell}`).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFD8D8D8" },
            };

            sheet0.getCell(`${currentCell}`).numFmt = '"$"#,##0';
          }
          //Total PrePO Cost
          sheet0.getCell(`FJ${i}`).value = { formula: `=SUM(EW${i}:FI${i})` };
          sheet0.getCell(`FJ${i}`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFD8D8D8" },
          };
          //Total PrePO Retail
          sheet0.getCell(`FW${i}`).value = { formula: `=SUM(FK${i}:FV${i})` };
          sheet0.getCell(`FW${i}`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFD8D8D8" },
          };
        }
        sheet0.autoFilter = {
          from: {
            row: 1,
            column: 1,
          },
          to: {
            row: itemDetailArr.length,
            column: totalColumns,
          },
        };

        for (let i = 1; i <= totalColumns; i++) {
          sheet0.getColumn(i).width = 15;
        }
      }
      setIsGridLoading(false);
    }

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      FileSaver.saveAs(blob, "ExportedItems.xlsx");
    });

    // handleClose();
  };
  const handleClick_SelectNone = () => {
    setSelectedItemList([]);
  };

  const handleClick_ImportPricing = () => {
    inputFile.current?.click();
    importType.current = "Pricing";
  };

  const handleClick_ImportBuyerQuantities = () => {
    inputFile.current?.click();
    importType.current = "Buyer";
  };

  const handleClick_ImportPlannerQuantities = () => {
    inputFile.current?.click();
    importType.current = "Planner";
  };

  const handleClick_ImportPrePOQuantities = () => {
    inputFile.current?.click();
    importType.current = "PrePO";
  };

  const handleClick_File = (e: MouseEvent<HTMLInputElement>) => {
    // clear file input on click to reset
    // ie: we read all file uploads despite same file gets uploaded/selected
    (e.target as HTMLInputElement).value = "";
  };

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files === null) {
      return;
    }
    setIsGridLoading(true);
    setMessage("");

    const file = e.target.files[0];

    if (file) {
      if (file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        setMessage("Please upload a .xlsx file");
        setIsGridLoading(false);
      } else {
        const wb = new ExcelJS.Workbook();
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = () => {
          const buffer = reader.result;
          if (buffer !== null && typeof buffer !== "string") {
            wb.xlsx.load(buffer).then((workbook) => {
              workbook.eachSheet((sheet, id) => {
                switch (importType.current) {
                  case "Pricing":
                    const PriceChanges: Array<PriceChangeObj> = [];

                    sheet.eachRow((row, rowIndex) => {
                      if (rowIndex !== 1) {
                        PriceChanges.push({
                          ItemNo: row.getCell("Item Code").value?.toString() || "",
                          ExchangeRate: row.getCell(31).value?.toString() || "",
                          ShippingRate: row.getCell(32).value?.toString() || "",
                          ForeignCost: row.getCell(33).value?.toString() || "",
                          LandedCost: row.getCell(34).result?.toString() || "",
                          Price: row.getCell(35).value?.toString() || "",
                        });
                      }
                    });
                    saveNewPrice(PriceChanges);
                    setIsGridLoading(false);
                    break;
                  case "Buyer":
                    const BuyerHold: Array<HoldQtyObj> = [];

                    sheet.eachRow((row, rowIndex) => {
                      if (rowIndex !== 1) {
                        BuyerHold.push({
                          ItemNo: row.getCell("Item Code").value?.toString() || "",
                          JanQty: row.getCell(61).value?.toString() || "",
                          FebQty: row.getCell(62).value?.toString() || "",
                          MarQty: row.getCell(63).value?.toString() || "",
                          AprQty: row.getCell(64).value?.toString() || "",
                          MayQty: row.getCell(65).value?.toString() || "",
                          JunQty: row.getCell(66).value?.toString() || "",
                          JulQty: row.getCell(67).value?.toString() || "",
                          AugQty: row.getCell(68).value?.toString() || "",
                          SepQty: row.getCell(69).value?.toString() || "",
                          OctQty: row.getCell(70).value?.toString() || "",
                          NovQty: row.getCell(71).value?.toString() || "",
                          DecQty: row.getCell(72).value?.toString() || "",
                        });
                      }
                    });
                    saveBuyerHold(BuyerHold);
                    setIsGridLoading(false);
                    break;
                  case "Planner":
                    const PlannerHold: Array<HoldQtyObj> = [];

                    sheet.eachRow((row, rowIndex) => {
                      if (rowIndex !== 1) {
                        PlannerHold.push({
                          ItemNo: row.getCell("Item Code").value?.toString() || "",
                          JanQty: row.getCell(100).value?.toString() || "",
                          FebQty: row.getCell(101).value?.toString() || "",
                          MarQty: row.getCell(102).value?.toString() || "",
                          AprQty: row.getCell(103).value?.toString() || "",
                          MayQty: row.getCell(104).value?.toString() || "",
                          JunQty: row.getCell(105).value?.toString() || "",
                          JulQty: row.getCell(106).value?.toString() || "",
                          AugQty: row.getCell(107).value?.toString() || "",
                          SepQty: row.getCell(108).value?.toString() || "",
                          OctQty: row.getCell(109).value?.toString() || "",
                          NovQty: row.getCell(110).value?.toString() || "",
                          DecQty: row.getCell(111).value?.toString() || "",
                        });
                      }
                    });
                    savePlannerHold(PlannerHold);
                    setIsGridLoading(false);
                    break;
                  case "PrePO":
                    const PrePO: Array<HoldQtyObj> = [];

                    sheet.eachRow((row, rowIndex) => {
                      PrePO.push({
                        ItemNo: row.getCell("Item Code").value?.toString() || "",
                        JanQty: row.getCell(139).value?.toString() || "",
                        FebQty: row.getCell(140).value?.toString() || "",
                        MarQty: row.getCell(141).value?.toString() || "",
                        AprQty: row.getCell(142).value?.toString() || "",
                        MayQty: row.getCell(143).value?.toString() || "",
                        JunQty: row.getCell(144).value?.toString() || "",
                        JulQty: row.getCell(145).value?.toString() || "",
                        AugQty: row.getCell(146).value?.toString() || "",
                        SepQty: row.getCell(147).value?.toString() || "",
                        OctQty: row.getCell(148).value?.toString() || "",
                        NovQty: row.getCell(149).value?.toString() || "",
                        DecQty: row.getCell(150).value?.toString() || "",
                      });
                    });
                    savePrePO(PrePO);
                    setIsGridLoading(false);
                    break;
                  default:
                    break;
                }
              });
            });
          }
        };
      }
    } else {
      setMessage("File could not be uploaded. Please try again.");
    }
  };

  const saveNewPrice = async (NewPrices: PriceChangeObj[]) => {
    const inputJSON: string = JSON.stringify(NewPrices);

    const res = await runFetch(
      spURL_POST + "Portal_Planning_NewPrice_Update",
      getReqOpt_POST(inputJSON)
    );

    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      setMessage(resObj.exception);
    } else if (resObj.status === "OK!") {
      // response === "" means no data found in table
    }
  };

  const saveBuyerHold = async (BuyerHold: HoldQtyObj[]) => {
    const inputJSON: string = JSON.stringify(BuyerHold);

    const res = await runFetch(
      spURL_POST + "Portal_Planning_BuyerHold_Update",
      getReqOpt_POST(inputJSON)
    );

    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      setMessage(resObj.exception);
    } else if (resObj.status === "OK!") {
      // response === "" means no data found in table
    }
  };

  const savePlannerHold = async (PlannerHold: HoldQtyObj[]) => {
    const inputJSON: string = JSON.stringify(PlannerHold);

    const res = await runFetch(
      spURL_POST + "Portal_Planning_PlannerHold_Update",
      getReqOpt_POST(inputJSON)
    );

    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      setMessage(resObj.exception);
    } else if (resObj.status === "OK!") {
      // response === "" means no data found in table
    }
  };

  const savePrePO = async (PrePO: HoldQtyObj[]) => {
    const inputJSON: string = JSON.stringify(PrePO);

    const res = await runFetch(
      spURL_POST + "Portal_Planning_PrePO_Update",
      getReqOpt_POST(inputJSON)
    );

    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      setMessage(resObj.exception);
    } else if (resObj.status === "OK!") {
      // response === "" means no data found in table
    }
  };

  useEffect(() => {
    refreshFilter();
  }, []);

  useEffect(() => {
    // resolve selected department (get rid of departments that are not )
  }, [SelectedBuyerList]);

  useEffect(() => {
    // resolve selected category
  }, [SelectedDepartmentList]);

  useEffect(() => {
    // resolve selected subcategory
  }, [SelectedCategoryList]);

  useEffect(() => {
    // resolve selected segment
  }, [SelectedSubCategoryList]);

  useEffect(() => {
    if (!showFilter) {
      getFilteredBuyerFilterList();
      getFilteredSupplierFilterList();
      getItems();
      setSelectedItemList([]);
    }
  }, [showFilter]);

  useEffect(() => {
    if (!showSort) {
      getFilteredBuyerFilterList();
      getFilteredSupplierFilterList();
      getItems();
      setSelectedItemList([]);
    }
  }, [showSort]);

  useEffect(() => {
    setSelectedItemList([]);
  }, [ProductView]);

  return (
    <div>
      <Header
        showSearch={showSearch}
        HeaderSource={"Planning"}
        ProductView={ProductView}
        setProductView={setProductView}
        setSearchDescription={setSearchDescription}
        handleClick_Search={handleClick_Search}
        handleClick_Filter={handleClick_Filter}
        handleClick_Sort={handleClick_Sort}
        handleClick_AddItem={() => {}}
        handleClick_Approve={() => {}}
        handleClick_Process={() => {}}
        handleClick_BuyerSummary={() => {}}
        handleClick_Summary={() => {}}
        handleClick_PlanningReport={handleClick_PlanningReport}
        handleClick_SelectAll={handleClick_SelectAll}
        handleClick_SelectNone={handleClick_SelectNone}
        handleClick_ImportPricing={handleClick_ImportPricing}
        handleClick_ImportBuyerQuantities={handleClick_ImportBuyerQuantities}
        handleClick_ImportPlannerQuantities={handleClick_ImportPlannerQuantities}
        handleClick_ImportPrePOQuantities={handleClick_ImportPrePOQuantities}
      />
      <div>
        {Message ? <p>{Message}</p> : null}
        {IsGridLoading ? (
          <Spinner className="spinner" animation="border" />
        ) : (
          <>
            {renderItems()}
            <div className="default-alignment">
              <Button variant="primary" onClick={handleClick_LoadMore}>
                Load More
              </Button>
            </div>
          </>
        )}

        <MyOffcanvas
          show={showFilter}
          setShow={setShowFilter}
          OffcanvasTitle="All filters"
          OffcanvasComponent={
            <>
              <div className="offcanvas-button-container">
                <div className="offcanvas-filter-clearall btnHover" onClick={handleClick_Clear}>
                  Clear All
                </div>
                {enableCustomFilter === false && (
                  <>
                    <div
                      className="offcanvas-filter-clearall btnHover"
                      onClick={handleClick_CustomFilter}
                    >
                      Custom Filter
                    </div>
                  </>
                )}
              </div>
              {enableCustomFilter === false && (
                <>
                  {renderItemFilters()}
                  {renderBuyerFilters()}
                  {renderSupplierFilters()}
                  {renderMiscFilters()}
                </>
              )}
            </>
          }
        />

        <MyOffcanvas
          show={showSort}
          setShow={setShowSort}
          OffcanvasTitle="Sort"
          OffcanvasComponent={<ul className="sort-list-container">{renderSort()}</ul>}
        />
      </div>

      <CustomFilterPopup
        show={showCustomFilter}
        action="Apply"
        setShow={setShowCustomFilter}
        doLookup={(batchNo) => {
          setBatchID(batchNo);
          setEnableCustomFilter(true);
        }}
      />

      <input
        type="file"
        id="file"
        ref={inputFile}
        onClick={handleClick_File}
        onChange={handleUpload}
        style={{ display: "none" }}
      />
    </div>
  );
}

export default Planning;
