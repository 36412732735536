import { ChangeEvent, ChangeEventHandler, SetStateAction } from "react";
import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { SortItem } from "./TestPage";

interface AddSortItemProps {
  show: boolean;
  setShow: React.Dispatch<SetStateAction<boolean>>;
  addSortItem: (_sortItem: SortItem) => void;
}

function PopupAddSortItem({ show, setShow, addSortItem }: AddSortItemProps) {
  const [PopupResponse, setPopupResponse] = useState<string>("");

  const [selectedSortObj, setSelectedSortObj] = useState<SortItem>({
    id: 0,
    SortType: "",
    SortOrder: "",
  });

  const handleClose = () => {
    setPopupResponse("");
    setShow(false);
  };

  const handleShow = () => {};

  const handleChange_SortType = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedSortObj({
      ...selectedSortObj,
      SortType: e.target.value,
    });
  };

  const handleChange_SortOrder = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedSortObj({
      ...selectedSortObj,
      SortOrder: e.target.value,
    });
  };
  const handleClick_Add = () => {
    addSortItem(selectedSortObj);
    setShow(false);
  };

  return (
    <>
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          onShow={handleShow}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Body>
            <div>
              <div className="default-alignment">
                <p>Sort Type:</p>
                <select onChange={handleChange_SortType}>
                  <option value=""></option>
                  <option value="SUPPLIER ACCOUNT">Supplier Account</option>
                  <option value="SELL PRICE">Sell Price</option>
                  <option value="LAST PO COST">Last PO Cost</option>
                </select>
              </div>
              <div className="default-alignment">
                <p>Order:</p>
                <select onChange={handleChange_SortOrder}>
                  <option value=""></option>
                  <option value="ASC">ASC</option>
                  <option value="DESC">DESC</option>
                </select>
              </div>
              <p className="error-message">{PopupResponse}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="popup-button-container">
              <div>
                <Button onClick={handleClose}>Close</Button>
              </div>
              <div>
                <Button onClick={handleClick_Add}>Add</Button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default PopupAddSortItem;
