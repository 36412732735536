import React, { Key, useEffect, useRef, useState } from "react";
import { getReqOpt_POST, runFetch, spURL_POST } from "../../functions/RunFetch";
import { useParams } from "react-router-dom";
import Image from "react-bootstrap/Image";
import { ConvertToCurrency } from "../../functions/ConvertToCurrency";
import Carousel from "react-bootstrap/Carousel";
import NoImgAvail from "../../assets/img/NoImgAvailable.jpg";

interface ItemDetailObj {
  ItemNo: string;
  Description: string;
  Category: string;
  SubCategory: string;
  ItemType: string;
  RangeStatus: string;
  Supplier: string;
  ReorderNumber: string;
  AvgCost: number;
  POCost: number;
  Price: number;
  AvgQtySoldL4W: number;
  MarginL4W: number;
  QtySoldH2HLY: number;
  QtySoldYTD: number;
  MaxUnitsSoldL4W: number;
  QtySoldL7D: number;
  QtySoldYesterday: number;
  TradingForecast: number;
  TradingForecast$: number;
  SOH: number;
  SOO: number;
  Committed: number;
  LWInstock: number;
  ForwardWeeksCover: number;
  Short10WMaxUnits: number;
  ProductImg: string;
  BoxImg: string;
  LifestyleImg: string;
  BigCommerceImg: string;
  ImgToShow: string[];
}

const emptyItemDetail = {
  ItemNo: "",
  Description: "",
  Category: "",
  SubCategory: "",
  ItemType: "",
  RangeStatus: "",
  Supplier: "",
  ReorderNumber: "",
  AvgCost: 0,
  POCost: 0,
  Price: 0,
  AvgQtySoldL4W: 0,
  MarginL4W: 0,
  QtySoldH2HLY: 0,
  QtySoldYTD: 0,
  MaxUnitsSoldL4W: 0,
  QtySoldL7D: 0,
  QtySoldYesterday: 0,
  TradingForecast: 0,
  TradingForecast$: 0,
  SOH: 0,
  SOO: 0,
  Committed: 0,
  LWInstock: 0,
  ForwardWeeksCover: 0,
  Short10WMaxUnits: 0,
  ProductImg: "",
  BoxImg: "",
  LifestyleImg: "",
  BigCommerceImg: "",
  ImgToShow: [],
};

const getImgToShowArr = (product: string, box: string, lifestyle: string, bigcommerce: string) => {
  return [product, box, lifestyle, bigcommerce].filter((item) => item !== "");
};

function DetailsPage() {
  const params = useParams();
  const ItemID: string = params.itemNo!;

  const [Message, setMessage] = useState("");

  const [ItemDetail, setItemDetail] = useState<ItemDetailObj>(emptyItemDetail);

  const getItem = async () => {
    setMessage("");

    const inputJSON: string = JSON.stringify({
      ItemID: ItemID,
    });

    const res = await runFetch(spURL_POST + "Portal_ItemDetail_Get", getReqOpt_POST(inputJSON));

    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      setMessage(resObj.exception);
    } else if (resObj.status === "OK!") {
      const spResponse: any = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setItemDetail({
          ItemNo: spResponse.Table[0].ItemNo,
          Description: spResponse.Table[0].Description,
          Category: spResponse.Table[0].Category,
          SubCategory: spResponse.Table[0].SubCategory,
          ItemType: spResponse.Table[0].ItemType,
          RangeStatus: spResponse.Table[0].RangeStatus,
          Supplier: spResponse.Table[0].Supplier,
          ReorderNumber: spResponse.Table[0].ReorderNumber,
          AvgCost: spResponse.Table[0].AvgCost,
          POCost: spResponse.Table[0].POCost,
          Price: spResponse.Table[0].Price,
          AvgQtySoldL4W: spResponse.Table[0].AvgQtySoldL4W,
          MarginL4W: spResponse.Table[0].MarginL4W,
          QtySoldH2HLY: spResponse.Table[0].QtySoldH2HLY,
          QtySoldYTD: spResponse.Table[0].QtySoldYTD,
          MaxUnitsSoldL4W: spResponse.Table[0].MaxUnitsSoldL4W,
          QtySoldL7D: spResponse.Table[0].QtySoldL7D,
          QtySoldYesterday: spResponse.Table[0].QtySoldYesterday,
          TradingForecast: spResponse.Table[0].TradingForecast,
          TradingForecast$: spResponse.Table[0].TradingForecast$,
          SOH: spResponse.Table[0].SOH,
          SOO: spResponse.Table[0].SOO,
          Committed: spResponse.Table[0].Committed,
          LWInstock: spResponse.Table[0].LWInstock,
          ForwardWeeksCover: spResponse.Table[0].ForwardWeeksCover,
          Short10WMaxUnits: spResponse.Table[0].Short10WMaxUnits,
          ProductImg: spResponse.Table[0].ProductImg,
          BoxImg: spResponse.Table[0].BoxImg,
          LifestyleImg: spResponse.Table[0].LifestyleImg,
          BigCommerceImg: spResponse.Table[0].BigCommerceImg,
          ImgToShow: getImgToShowArr(
            spResponse.Table[0].ProductImg,
            spResponse.Table[0].BoxImg,
            spResponse.Table[0].LifestyleImg,
            spResponse.Table[0].BigCommerceImg
          ),
        });
      }
    }
  };

  useEffect(() => {
    getItem();
  }, []);

  return (
    <>
      <div className="pdp-page-container">
        <div className="pdp-image-wrapper">
          {ItemDetail.ImgToShow.length === 0 ? (
            <div className="pdp-image-continer">
              <Image className="pdp-image" src={NoImgAvail} rounded alt="No image available" />
            </div>
          ) : (
            <>
              <Carousel data-bs-theme="dark">
                {ItemDetail.ImgToShow.map((image, index) => (
                  <Carousel.Item>
                    <div className="pdp-image-continer">
                      <Image
                        key={index}
                        className="pdp-image"
                        src={image}
                        rounded
                        alt="Images for the product"
                      />
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            </>
          )}
        </div>

        <div className="pdp-header-wrapper">
          <div className="pdp-header-container pdp-container">
            <div className="pdp-description">{ItemDetail.Description}</div>
            <div className="pdp-price">{ItemDetail.ItemNo}</div>
          </div>
        </div>

        {/* <div className="pdp-body-container pdp-container">
          <div className="pdp-body-row-title">{ItemDetail.ItemNo}</div>

          <div className="pdp-body-row">
            <div className="pdp-body-row-title">Qty Sold Yesterday</div>
            <div className="pdp-body-row-title">Qty Sold L7D</div>
            <div className="pdp-body-row-title">Qty Sold YTD</div>
          </div>
          <div className="pdp-body-row">
            <div>{ItemDetail.QtySoldYesterday}</div>
            <div>{ItemDetail.QtySoldL7D}</div>
            <div>{ItemDetail.QtySoldYTD}</div>
          </div>
          <div className="pdp-body-row">
            <div className="pdp-body-row-title">SOH</div>
            <div className="pdp-body-row-title">SOO</div>
            <div className="pdp-body-row-title">Committed</div>
          </div>
          <div className="pdp-body-row">
            <div>{ItemDetail.SOH}</div>
            <div>{ItemDetail.SOO}</div>
            <div>{ItemDetail.Committed}</div>
          </div>
          <div className="pdp-body-row">
            <div className="pdp-body-row-title">LW Instock %</div>
            <div className="pdp-body-row-title">Weeks Cover</div>
          </div>
          <div className="pdp-body-row">
            <div>{ItemDetail.LWInstock}</div>
            <div>{ItemDetail.ForwardWeeksCover}</div>
          </div>
          <div className="pdp-body-row">
            <div className="pdp-body-row-title">Price</div>
            <div className="pdp-body-row-title">Avg Sell L4W</div>
            <div className="pdp-body-row-title">Margin L4W</div>
          </div>
          <div className="pdp-body-row">
            <div>{ConvertToCurrency(ItemDetail.Price)}</div>
            <div>{ConvertToCurrency(ItemDetail.AvgQtySoldL4W)}</div>
            <div>{ConvertToCurrency(ItemDetail.MarginL4W, 0)}</div>
          </div>
          <div className="pdp-body-row">
            <div>{ItemDetail.Supplier}</div>
            <div className="pdp-body-row-title">Item Number</div>
          </div>
          <div className="pdp-body-row">
            <div>{ItemDetail.ReorderNumber}</div>
            <div>{ItemDetail.ItemNo}</div>
          </div>
          <div className="pdp-body-row">
            <div className="pdp-body-row-title">Avg Cost</div>
            <div className="pdp-body-row-title">Last PO Cost</div>
            <div className="pdp-body-row-title">Short 10 Weeks Max Units $</div>
          </div>
          <div className="pdp-body-row">
            <div>{ConvertToCurrency(ItemDetail.AvgCost)}</div>
            <div>{ConvertToCurrency(ItemDetail.POCost)}</div>
            <div>{ConvertToCurrency(ItemDetail.Short10WMaxUnits)}</div>
          </div>
          <div>
            <div className="pdp-body-row">
              <div className="pdp-body-row-title">Qty Sold H2H LY</div>
              <div className="pdp-body-row-title">Max Units Sold L4W</div>
            </div>
            <div className="pdp-body-row">
              <div>{ItemDetail.QtySoldH2HLY}</div>
              <div>{ItemDetail.MaxUnitsSoldL4W}</div>
            </div>
            <div className="pdp-body-row">
              <div className="pdp-body-row-title">Trading Forecast</div>
              <div className="pdp-body-row-title">Trading Forecast $</div>
            </div>
            <div className="pdp-body-row">
              <div>{ItemDetail.TradingForecast}</div>
              <div>{ConvertToCurrency(ItemDetail.TradingForecast$, 0)}</div>
            </div>
          </div>
          <div className="pdp-body-row">
            <div>{ItemDetail.Category}</div>
            <div>{ItemDetail.SubCategory}</div>
          </div>
          <div className="pdp-body-row">
            <div>{ItemDetail.ItemType}</div>
            <div>{ItemDetail.RangeStatus}</div>
          </div>
        </div> */}

        <div className="pdp-center">
          <div className="padding-3"></div>
          <table className="pdp-table">
            {/* <thead>
              <tr>
                <td colSpan={3}>
                  <h5>Sales</h5>
                </td>
              </tr>
            </thead> */}
            <tbody>
              <tr>
                <td>
                  <h6>Qty Sold Yesterday</h6>
                  {ItemDetail.QtySoldYesterday}
                </td>
                <td>
                  <h6>Qty Sold L7D</h6>
                  {ItemDetail.QtySoldL7D}
                </td>
                <td>
                  <h6>Qty Sold YTD</h6>
                  {ItemDetail.QtySoldYTD}
                </td>
              </tr>
            </tbody>
          </table>
          <div className="padding-3"></div>

          <table className="pdp-table">
            {/* <thead>
              <tr>
                <td colSpan={3}>
                  <h5>Stock</h5>
                </td>
              </tr>
            </thead> */}
            <tbody>
              <tr>
                <td>
                  <h6>SOH</h6>
                  {ItemDetail.SOH}
                </td>
                <td>
                  <h6>SOO</h6>
                  {ItemDetail.SOO}
                </td>
                <td>
                  <h6>Committed</h6>
                  {ItemDetail.Committed}
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <h6>LW Instock %</h6>
                  {ItemDetail.LWInstock}
                </td>

                <td>
                  <h6>Weeks Cover</h6>
                  {ItemDetail.ForwardWeeksCover}
                </td>
              </tr>
            </tbody>
          </table>
          <div className="padding-3"></div>
          <table className="pdp-table">
            {/* <thead>
              <tr>
                <td colSpan={3}>
                  <h5>Pricing</h5>
                </td>
              </tr>
            </thead> */}
            <tbody>
              <tr>
                <td>
                  <h6>Price</h6>
                  {ConvertToCurrency(ItemDetail.Price)}
                </td>
                <td>
                  <h6>Avg Qty Sold L4W</h6>
                  {ItemDetail.AvgQtySoldL4W}
                </td>
                <td>
                  <h6>Margin L4W</h6>
                  {ConvertToCurrency(ItemDetail.MarginL4W, 0)}
                </td>
              </tr>
            </tbody>
          </table>
          <div className="padding-3"></div>
          <table className="pdp-table">
            {/* <thead>
              <tr>
                <td colSpan={3}>
                  <h5>Reorder</h5>
                </td>
              </tr>
            </thead> */}
            <tbody>
              <tr>
                <td>
                  <h6>Supplier</h6>
                  {ItemDetail.Supplier}
                </td>
                <td>
                  <h6>Reorder No</h6>
                  {ItemDetail.ReorderNumber}
                </td>
                <td>
                  <h6>Avg Cost</h6>
                  {ConvertToCurrency(ItemDetail.AvgCost)}
                </td>
              </tr>
              <tr>
                <td>
                  <h6>Last PO Cost</h6>
                  {ConvertToCurrency(ItemDetail.POCost)}
                </td>

                <td colSpan={2}>
                  <h6>Short 10 Weeks Max Units $</h6>
                  {ConvertToCurrency(ItemDetail.Short10WMaxUnits, 0)}
                </td>
              </tr>
            </tbody>
          </table>
          <div className="padding-3"></div>

          <table className="pdp-table">
            {/* <thead>
              <tr>
                <td colSpan={2}>
                  <h5>Forecast</h5>
                </td>
              </tr>
            </thead> */}
            <tbody>
              <tr>
                <td>
                  <h6>Qty Sold H2H LY</h6>
                  {ItemDetail.QtySoldH2HLY}
                </td>
                <td>
                  <h6>Max Units Sold L4W</h6>
                  {ItemDetail.MaxUnitsSoldL4W}
                </td>
              </tr>
              <tr>
                <td>
                  <h6>Trading Forecast</h6>
                  {ItemDetail.TradingForecast}
                </td>
                <td>
                  <h6>Trading Forecast $</h6>
                  {ConvertToCurrency(ItemDetail.TradingForecast$, 0)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="default-alignment">
        <div>{ItemDetail.Category}</div>
        <div>{ItemDetail.SubCategory}</div>
        <div>{ItemDetail.ItemType}</div>
        <div>{ItemDetail.RangeStatus}</div>
      </div>
    </>
  );
}

export default DetailsPage;
