import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useState } from "react";
import SortableItem from "./SortableItem";
import PopupAddSortItem from "./PopupAddSortItem";

export type SortItem = {
  id: number;
  SortType: string;
  SortOrder: string;
};

function TestPage() {
  const defaultSortItems: SortItem[] = [];
  const [currentSortItems, setCurrentSortItems] = useState<SortItem[]>(defaultSortItems);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [FinalisedSort, setFinalisedSort] = useState<string>("");

  const [showAddSort, setShowAddSort] = useState<boolean>(false);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over) return;

    if (active.id !== over?.id) {
      setCurrentSortItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const handleClick_AddSort = () => {
    setShowAddSort(true);
  };

  const addSortItem = (_sortItem: SortItem) => {
    let newSortItems: SortItem[] = currentSortItems;
    const _currentSortItemCount = currentSortItems.length;
    newSortItems.push({
      ..._sortItem,
      id: _currentSortItemCount,
    });

    setCurrentSortItems(newSortItems);
  };

  const handleClick_FinaliseSort = () => {
    setFinalisedSort(
      JSON.stringify(
        currentSortItems.map((item, index) => ({
          ...item,
          order: index,
        }))
      )
    );
  };

  return (
    <div>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={currentSortItems}
          strategy={verticalListSortingStrategy}
        >
          {currentSortItems.map((id) => (
            <SortableItem
              key={id.id}
              id={id.id}
              content={`${id.SortType}-${id.SortOrder}`}
            />
          ))}
        </SortableContext>
      </DndContext>
      <button onClick={handleClick_AddSort}>Add sort...</button>

      <button onClick={handleClick_FinaliseSort}>Finalise sort</button>

      <p>
        <strong>{FinalisedSort}</strong>
      </p>

      <PopupAddSortItem
        show={showAddSort}
        setShow={setShowAddSort}
        addSortItem={addSortItem}
      />
    </div>
  );
}

export default TestPage;
