import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface AdvancedSortableItemPropObj {
  key: number;
  id: number;
  content: string;
  deleteSort: () => void;
}

function AdvancedSortableItem(props: AdvancedSortableItemPropObj) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.id,
  });

  const itemStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      className="sort-item-container"
      style={itemStyle}
      ref={setNodeRef}
    >
      <li
        className="no-highlight sort-item"
        {...attributes}
        {...listeners}
      >
        {props.content}
      </li>
      <FontAwesomeIcon
        icon={solid("xmark")}
        className="btnHover color-red"
        onClick={props.deleteSort}
      />
    </div>
  );
}

export default AdvancedSortableItem;
