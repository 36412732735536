import { useSortable } from "@dnd-kit/sortable";

interface SortableItemPropObj {
  key: number;
  id: number;
  content: string;
}

function SortableItem(props: SortableItemPropObj) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.id,
  });

  const style = {
    backgroundColor: "lightblue",
    padding: "5px",
    margin: "5px",
  };

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    >
      <p style={style}>{props.content}</p>
    </div>
  );
}

export default SortableItem;
