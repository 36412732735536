import { Key, MouseEvent, SetStateAction, useEffect, useState } from "react";
import { Accordion, Button, ButtonGroup, Collapse, Offcanvas, Spinner } from "react-bootstrap";
import Header from "../../components/Header";
import { getReqOpt_POST, runFetch, spURL_POST } from "../../functions/RunFetch";
import FilterAccordion from "../../components/FilterAccordion";
import ItemCardLarge from "../../components/ItemCardLarge";
import { IsArrayEmpty } from "../../functions/IsArrayEmpty";
import { ConvertToCurrency } from "../../functions/ConvertToCurrency";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { IconDefinition, IconProp } from "@fortawesome/fontawesome-svg-core";
import { StringWithNoSpace } from "../../functions/StringWithNoSpace";
import { GetValueOfKey } from "../../functions/GetValueOfKey";
import MyOffcanvas from "../../components/MyOffcanvas";
import SummaryPopup from "../../components/PopupSummary";
import CustomFilterPopup from "../../components/PopupCustomFilter";
import SearchBar from "../../components/SearchBar";
import { useBeforeunload } from "react-beforeunload";
import NoImgAvail from "../../assets/img/NoImgAvailable.jpg";
import ItemCardSmall from "../../components/ItemCardSmall";
import AdvancedSortComponent from "../../components/AdvancedSortComponent";
import store from "../../store";

export type SortItem = {
  id: number;
  SortTitle: string;
  SortType: string;
  SortOrder: string;
};

interface SourcingItemObj {
  ItemNo: string;
  ImgURL: string;
  Description: string;
  SupplierAccount: string;
  Manufacturer: string;
  ReorderNumber: string;
  Category: string;
  SubCategory: string;
  Segment: string;
  ItemPlanning: string;
  PrivateLabel: string;
  Seasonality: string;
  RangeStatus: string;
  ItemType: string;
  MarginType: string;
  LastPO: string;
  AvgCost: number;
  LastPOCost: number;
  Price: number;
  ForeignCost: number;
  MSRP: number;
  CloseoutPrice: number;
  MarginP: number;
  SellThrough: number;
  SelloutMarginP: number;
  Age: string;
  ReviewForecast: number;
  ReviewForecastRetail: number;
  TradingForecast: number;
  TradingForecastRetail: number;
  Forecast52W: number;
  Forecast52WRetail: number;
  QtySoldL7D: number;
  SOHQty: number;
  SOHRetail: number;
  CommittedQty: number;
  CommittedRetail: number;
  SOHStoreQty: number;
  SOHStoreRetail: number;
  SupplierHoldQty: number;
  SupplierHoldRetail: number;
  ShortMaxUnits: number;
  CatOTBAfterMonth: number;
  MarginDifference: number;
  Order: number;
  NewCommit: number;
  SoldLWQty: number;
  SoldLWRetail: number;
  SoldLMQty: number;
  SoldLMRetail: number;
  SoldYTDQty: number;
  SoldYTDRetail: number;
  SoldL52WQty: number;
  SoldL52WRetail: number;
  SoldLYQty: number;
  SoldLYRetail: number;
  SoldH2HQty: number;
  SoldH2HRetail: number;
  MaxInstockL4W: number;
  InstockLW: number;
  SOHWOC: number;
  CommittedWOC: number;
  RankLW: number;
  RankLM: number;
  RankYTD: number;
  RankLY: number;
  RankH2H: number;
  RankRRF: number;
  RankTAF: number;
  Rank52F: number;
  LeadCompName: string;
  LeadCompPrice: number;
  NPDStatus: string;
  NPDUnitsLW: number;
  NPDMarketShareL4W: number;
  NPDMarketShareL13W: number;
}

interface BuyerFilterObj {
  Buyer: string;
  Department: string;
  Category: string;
  SubCategory: string;
  Segment: string;
}

interface FilteredBuyerFilterObj {
  Buyer: string[];
  Department: string[];
  Category: string[];
  SubCategory: string[];
  Segment: string[];
}

interface ItemFilterObj {
  FieldName: string;
  Value: string;
}

interface SupplierFilterObj {
  SupplierGroup: string;
  Supplier: string;
}

interface ManufacturerFilterObj {
  Manufacturer: string;
}

interface BuyingManagerFilterObj {
  BuyingManager: string;
}

interface HeaderRowObj {
  Title: string;
  AllowSort: boolean;
}

function ItemPLP() {
  useBeforeunload(() => {
    return "Are you sure to refresh this tab? Settings will be closed.";
  });

  const [Message, setMessage] = useState<string>("");
  const [SourcingItemList, setSourcingItemList] = useState<SourcingItemObj[]>([]);
  const [ItemFilterList, setItemFilterList] = useState<ItemFilterObj[]>([]);
  const [BuyerFilterList, setBuyerFilterList] = useState<BuyerFilterObj[]>([]);
  const [FilteredBuyerFilterList, setFilteredBuyerFilterList] = useState<FilteredBuyerFilterObj>({
    Buyer: [],
    Department: [],
    Category: [],
    SubCategory: [],
    Segment: [],
  });
  const [SupplierFilterList, setSupplierFilterList] = useState<SupplierFilterObj[]>([]);
  const [FilteredSupplierFilterList, setFilteredSupplierFilterList] = useState<SupplierFilterObj[]>(
    []
  );
  const [ManufacturerFilterList, setManufacturerFilterList] = useState<ManufacturerFilterObj[]>([]);
  const [BuyingManagerFilterList, setBuyingManagerFilterList] = useState<BuyingManagerFilterObj[]>(
    []
  );

  const [SelectedItemPlanningList, setSelectedItemPlanningList] = useState<string[]>([]);
  const [SelectedItemTypeList, setSelectedItemTypeList] = useState<string[]>([]);
  const [SelectedItemSourceList, setSelectedItemSourceList] = useState<string[]>([]);
  const [SelectedRangeStatusList, setSelectedRangeStatusList] = useState<string[]>([]);
  const [SelectedMarginBandList, setSelectedMarginBandList] = useState<string[]>([]);
  const [SelectedPricePointList, setSelectedPricePointList] = useState<string[]>([]);
  const [SelectedAgeList, setSelectedAgeList] = useState<string[]>([]);
  const [SelectedCoverStatusSOHList, setSelectedCoverStatusSOHList] = useState<string[]>([]);
  const [SelectedCoverStatusCommittedList, setSelectedCoverStatusCommittedList] = useState<
    string[]
  >([]);
  const [SelectedPrivateLabelList, setSelectedPrivateLabelList] = useState<string[]>([]);
  const [SelectedSeasonalityList, setSelectedSeasonalityList] = useState<string[]>([]);
  const [SelectedCompetitorList, setSelectedCompetitorList] = useState<string[]>([]);
  const [SelectedBuyerList, setSelectedBuyerList] = useState<string[]>([]);
  const [SelectedDepartmentList, setSelectedDepartmentList] = useState<string[]>([]);
  const [SelectedCategoryList, setSelectedCategoryList] = useState<string[]>([]);
  const [SelectedSubCategoryList, setSelectedSubCategoryList] = useState<string[]>([]);
  const [SelectedSegmentList, setSelectedSegmentList] = useState<string[]>([]);
  const [SelectedSupplierGroupList, setSelectedSupplierGroupList] = useState<string[]>([]);
  const [SelectedSupplierList, setSelectedSupplierList] = useState<string[]>([]);
  const [SelectedManufacturerList, setSelectedManufacturerList] = useState<string[]>([]);
  const [SelectedCrossCategoryList, setSelectedCrossCategoryList] = useState<string[]>([]);
  const [SelectedBuyerPicksList, setSelectedBuyerPicksList] = useState<string[]>([]);
  const [SelectedMerchandisingTraitsList, setSelectedMerchandisingTraitsList] = useState<string[]>(
    []
  );
  const [SelectedPrivateLabelBrandList, setSelectedPrivateLabelBrandList] = useState<string[]>([]);
  const [SelectedNPDStatusList, setSelectedNPDStatusList] = useState<string[]>([]);
  const [SelectedNPDMarketShareList, setSelectedNPDMarketShareList] = useState<string[]>([]);
  const [SelectedInstockStatusList, setSelectedInstockStatusList] = useState<string[]>([]);
  const [SelectedSellThroughList, setSelectedSellThroughList] = useState<string[]>([]);
  const [SelectedRRForecastBandList, setSelectedRRForecastBandList] = useState<string[]>([]);
  const [SelectedTradingForecastBandList, setSelectedTradingForecastBandList] = useState<string[]>(
    []
  );
  const [SelectedH2HSalesBandList, setSelectedH2HSalesBandList] = useState<string[]>([]);
  const [SelectedBuyingManagerList, setSelectedBuyingManagerList] = useState<string[]>([]);
  const [Selected13WKForecastRetailList, setSelected13WKForecastRetailList] = useState<string[]>(
    []
  );
  const [Selected52WKForecastRetailList, setSelected52WKForecastRetailList] = useState<string[]>(
    []
  );
  const [Selected13WKForecastUnitList, setSelected13WKForecastUnitList] = useState<string[]>([]);
  const [Selected52WKForecastUnitList, setSelected52WKForecastUnitList] = useState<string[]>([]);

  const [SelectedItemList, setSelectedItemList] = useState<string[]>([]);

  const [PageNo, setPageNo] = useState<number>(1);

  const [sortColumn, setSortColumn] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");
  const [sortIcon, setSortIcon] = useState<IconDefinition>(solid("sort"));

  const [clearTriggered, setClearTriggered] = useState<boolean>(false);

  const [SearchDescription, setSearchDescription] = useState<string>("");
  const [Mode, setMode] = useState<string>("Range Review");

  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [showSort, setShowSort] = useState<boolean>(false);
  const [showProductView, setShowProductView] = useState<boolean>(false);
  const [showSummary, setShowSummary] = useState<boolean>(false);
  const [showCustomFilter, setShowCustomFilter] = useState<boolean>(false);
  const [enableCustomFilter, setEnableCustomFilter] = useState<boolean>(false);
  const [batchID, setBatchID] = useState<string>("");

  const [ProductView, setProductView] = useState<string>("large");

  const [IsGridLoading, setIsGridLoading] = useState(false);

  // advanced sort
  const [AdvancedSortItems, ,] = store.useState<string>("advancedSortItems");

  // advanced filter grouping
  const [showFilterGroup_Buyer, setShowFilterGroup_Buyer] = useState<boolean>(false);
  const [showFilterGroup_Supplier, setShowFilterGroup_Supplier] = useState<boolean>(false);
  const [showFilterGroup_KeyItemStatus, setShowFilterGroup_KeyItemStatus] =
    useState<boolean>(false);
  const [showFilterGroup_NPD, setShowFilterGroup_NPD] = useState<boolean>(false);
  const [showFilterGroup_PrivateLabel, setShowFilterGroup_PrivateLabel] = useState<boolean>(false);

  const getFilterList = async () => {
    const res = await runFetch(spURL_POST + "Portal_ItemList_Filter_Get", getReqOpt_POST("[{}]"));

    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      setMessage(resObj.exception);
    } else if (resObj.status === "OK!") {
      const spResponse: any = JSON.parse(resObj.response);
      //const spResponse: any = resObj.response;
      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setItemFilterList(
          spResponse.Table.map((item: ItemFilterObj) => ({
            FieldName: item.FieldName,
            Value: item.Value,
          }))
        );
      }

      if (spResponse.Table1 !== undefined && spResponse.Table1.length > 0) {
        setBuyerFilterList(
          spResponse.Table1.map((item: BuyerFilterObj) => ({
            Buyer: item.Buyer,
            Department: item.Department,
            Category: item.Category,
            SubCategory: item.SubCategory,
            Segment: item.Segment,
          }))
        );

        setFilteredBuyerFilterList({
          Buyer: getUniqueElementsOfArray(
            spResponse.Table1.map((item: BuyerFilterObj) => item.Buyer)
          ),
          Department: getUniqueElementsOfArray(
            spResponse.Table1.map((item: BuyerFilterObj) => item.Department)
          ),
          Category: getUniqueElementsOfArray(
            spResponse.Table1.map((item: BuyerFilterObj) => item.Category)
          ),
          SubCategory: getUniqueElementsOfArray(
            spResponse.Table1.map((item: BuyerFilterObj) => item.SubCategory)
          ),
          Segment: getUniqueElementsOfArray(
            spResponse.Table1.map((item: BuyerFilterObj) => item.Segment)
          ),
        });
      }
      if (spResponse.Table2 !== undefined && spResponse.Table2.length > 0) {
        setSupplierFilterList(
          spResponse.Table2.map((item: SupplierFilterObj) => ({
            SupplierGroup: item.SupplierGroup,
            Supplier: item.Supplier,
          }))
        );
        setFilteredSupplierFilterList(
          spResponse.Table2.map((item: SupplierFilterObj) => ({
            SupplierGroup: item.SupplierGroup,
            Supplier: item.Supplier,
          }))
        );
      }
      if (spResponse.Table3 !== undefined && spResponse.Table3.length > 0) {
        setManufacturerFilterList(
          spResponse.Table3.map((item: ManufacturerFilterObj) => ({
            Manufacturer: item.Manufacturer,
          }))
        );
      }

      if (spResponse.Table5 !== undefined && spResponse.Table5.length > 0) {
        setBuyingManagerFilterList(
          spResponse.Table5.map((item: BuyingManagerFilterObj) => ({
            BuyingManager: item.BuyingManager,
          }))
        );
      }
    }
  };

  const getItems = async () => {
    setMessage("");
    setIsGridLoading(true);

    const inputJSON: string = JSON.stringify([
      { FilterName: "ITEM PLANNING", FilterValue: SelectedItemPlanningList },
      { FilterName: "ITEM SOURCE", FilterValue: SelectedItemSourceList },
      { FilterName: "ITEM TYPE", FilterValue: SelectedItemTypeList },
      { FilterName: "RANGE STATUS", FilterValue: SelectedRangeStatusList },
      { FilterName: "MARGIN BAND", FilterValue: SelectedMarginBandList },
      { FilterName: "PRICE POINT", FilterValue: SelectedPricePointList },
      { FilterName: "AGE", FilterValue: SelectedAgeList },
      { FilterName: "COVER STATUS SOH", FilterValue: SelectedCoverStatusSOHList },
      { FilterName: "COVER STATUS COMMITTED", FilterValue: SelectedCoverStatusCommittedList },
      { FilterName: "PRIVATE LABEL", FilterValue: SelectedPrivateLabelList },
      { FilterName: "SEASONALITY", FilterValue: SelectedSeasonalityList },
      { FilterName: "CROSS CATEGORY", FilterValue: SelectedCrossCategoryList },
      { FilterName: "COMPETITOR", FilterValue: SelectedCompetitorList },
      { FilterName: "BUYER PICKS", FilterValue: SelectedBuyerPicksList },
      { FilterName: "MERCHANDISING TRAITS", FilterValue: SelectedMerchandisingTraitsList },
      { FilterName: "PRIVATE LABEL BRAND", FilterValue: SelectedPrivateLabelBrandList },
      { FilterName: "NPD STATUS", FilterValue: SelectedNPDStatusList },
      { FilterName: "NPD MARKET SHARE", FilterValue: SelectedNPDMarketShareList },
      { FilterName: "INSTOCK STATUS", FilterValue: SelectedInstockStatusList },
      { FilterName: "SELL THROUGH", FilterValue: SelectedSellThroughList },
      { FilterName: "RR FORECAST BAND", FilterValue: SelectedRRForecastBandList },
      { FilterName: "TRADING FORECAST BAND", FilterValue: SelectedTradingForecastBandList },
      { FilterName: "H2H SALES BAND", FilterValue: SelectedH2HSalesBandList },
      { FilterName: "BUYER", FilterValue: SelectedBuyerList },
      { FilterName: "DEPARTMENT", FilterValue: SelectedDepartmentList },
      { FilterName: "CATEGORY", FilterValue: SelectedCategoryList },
      { FilterName: "SUBCATEGORY", FilterValue: SelectedSubCategoryList },
      { FilterName: "SEGMENT", FilterValue: SelectedSegmentList },
      { FilterName: "SUPPLIER GROUP", FilterValue: SelectedSupplierGroupList },
      { FilterName: "SUPPLIER", FilterValue: SelectedSupplierList },
      { FilterName: "MANUFACTURER", FilterValue: SelectedManufacturerList },
      { FilterName: "BUYING MANAGER", FilterValue: SelectedBuyingManagerList },
      { FilterName: "13 WK FORECAST RETAIL", FilterValue: Selected13WKForecastRetailList },
      { FilterName: "52 WK FORECAST RETAIL", FilterValue: Selected52WKForecastRetailList },
      { FilterName: "13 WK FORECAST UNIT", FilterValue: Selected13WKForecastUnitList },
      { FilterName: "52 WK FORECAST UNIT", FilterValue: Selected52WKForecastUnitList },
      { FilterName: "SEARCH", FilterValue: SearchDescription },
      { FilterName: "PAGE", FilterValue: PageNo },
      { FilterName: "SORT", FilterValue: JSON.parse(AdvancedSortItems) },
      { FilterName: "CUSTOM FILTER", FilterValue: batchID },
    ]);

    const res = await runFetch(spURL_POST + "Portal_ItemList_Get", getReqOpt_POST(inputJSON));

    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      setMessage(resObj.exception);
    } else if (resObj.status === "OK!") {
      // response === "" means no data found in table
      if (resObj.response === "") {
        setSourcingItemList([]);
      } else {
        const spResponse: any = JSON.parse(resObj.response);
        //const spResponse: any = resObj.response;

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          setSourcingItemList(
            spResponse.Table.map((item: SourcingItemObj) => ({
              ItemNo: item.ItemNo,
              ImgURL: item.ImgURL,
              Description: item.Description,
              SupplierAccount: item.SupplierAccount,
              Manufacturer: item.Manufacturer,
              ReorderNumber: item.ReorderNumber,
              Category: item.Category,
              SubCategory: item.SubCategory,
              Segment: item.Segment,
              ItemPlanning: item.ItemPlanning,
              PrivateLabel: item.PrivateLabel,
              Seasonality: item.Seasonality,
              RangeStatus: item.RangeStatus,
              ItemType: item.ItemType,
              MarginType: item.MarginType,
              LastPO: item.LastPO,
              AvgCost: item.AvgCost,
              LastPOCost: item.LastPOCost,
              Price: item.Price,
              ForeignCost: item.ForeignCost,
              MSRP: item.MSRP,
              CloseoutPrice: item.CloseoutPrice,
              MarginP: item.MarginP,
              SellThrough: item.SellThrough,
              SelloutMarginP: item.SelloutMarginP,
              Age: item.Age,
              ReviewForecast: item.ReviewForecast,
              ReviewForecastRetail: item.ReviewForecastRetail,
              TradingForecast: item.TradingForecast,
              TradingForecastRetail: item.TradingForecastRetail,
              Forecast52W: item.Forecast52W,
              Forecast52WRetail: item.Forecast52WRetail,
              QtySoldL7D: item.QtySoldL7D,
              SOHQty: item.SOHQty,
              SOHRetail: item.SOHRetail,
              CommittedQty: item.CommittedQty,
              CommittedRetail: item.CommittedRetail,
              SOHStoreQty: item.SOHStoreQty,
              SOHStoreRetail: item.SOHStoreRetail,
              SupplierHoldQty: item.SupplierHoldQty,
              SupplierHoldRetail: item.SupplierHoldRetail,
              ShortMaxUnits: item.ShortMaxUnits,
              CatOTBAfterMonth: item.CatOTBAfterMonth,
              MarginDifference: item.MarginDifference,
              Order: item.Order,
              NewCommit: item.NewCommit,
              SoldLWQty: item.SoldLWQty,
              SoldLWRetail: item.SoldLWRetail,
              SoldLMQty: item.SoldLMQty,
              SoldLMRetail: item.SoldLMRetail,
              SoldYTDQty: item.SoldYTDQty,
              SoldYTDRetail: item.SoldYTDRetail,
              SoldL52WQty: item.SoldL52WQty,
              SoldL52WRetail: item.SoldL52WRetail,
              SoldLYQty: item.SoldLYQty,
              SoldLYRetail: item.SoldLYRetail,
              SoldH2HQty: item.SoldH2HQty,
              SoldH2HRetail: item.SoldH2HRetail,
              MaxInstockL4W: item.MaxInstockL4W,
              InstockLW: item.InstockLW,
              SOHWOC: item.SOHWOC,
              CommittedWOC: item.CommittedWOC,
              RankLW: item.RankLW,
              RankLM: item.RankLM,
              RankYTD: item.RankYTD,
              RankLY: item.RankLY,
              RankH2H: item.RankH2H,
              RankRRF: item.RankRRF,
              RankTAF: item.RankTAF,
              Rank52F: item.Rank52F,
              LeadCompName: item.LeadCompName,
              LeadCompPrice: item.LeadCompPrice,
              NPDStatus: item.NPDStatus,
              NPDUnitsLW: item.NPDUnitsLW,
              NPDMarketShareL4W: item.NPDMarketShareL4W,
              NPDMarketShareL13W: item.NPDMarketShareL13W,
            }))
          );
        }
      }
    }

    setIsGridLoading(false);
  };

  const onFilterItemListChange = (setFunction: Function, list: string[]) => {
    setFunction(list);
    setPageNo(1);
  };

  const getUniqueElementsOfArray = (arr: any[]) => {
    return arr.filter(function (value, index, array) {
      return array.indexOf(value) === index;
    });
  };

  function resolvedSelectedBuyerFilters() {
    let selectedBuyerList = BuyerFilterList.filter((item) =>
      SelectedBuyerList.includes(item.Buyer)
    ).map((item) => item.Buyer);

    // keep only departments that are attached to selected buyers
    let selectedDepartmentList = getUniqueElementsOfArray(
      BuyerFilterList.map((item) => ({
        Buyer: item.Buyer,
        Department: item.Department,
      }))
        .filter((item) => selectedBuyerList.includes(item.Buyer))
        .map((item) => item.Department)
    );

    // keep only departments that are attached to selected buyers
    let selectedCategoryList = getUniqueElementsOfArray(
      BuyerFilterList.map((item) => ({
        Department: item.Department,
        Category: item.Category,
      }))
        .filter((item) => selectedDepartmentList.includes(item.Department))
        .map((item) => item.Category)
    );

    // keep only departments that are attached to selected buyers
    let selectedSubCategoryList = getUniqueElementsOfArray(
      BuyerFilterList.map((item) => ({
        Category: item.Category,
        SubCategory: item.SubCategory,
      }))
        .filter((item) => selectedCategoryList.includes(item.Category))
        .map((item) => item.SubCategory)
    );

    // keep only departments that are attached to selected buyers
    let selectedSegmentList = getUniqueElementsOfArray(
      BuyerFilterList.map((item) => ({
        SubCategory: item.SubCategory,
        Segment: item.Segment,
      }))
        .filter((item) => selectedSubCategoryList.includes(item.SubCategory))
        .map((item) => item.Segment)
    );

    setSelectedDepartmentList(selectedDepartmentList);
    setSelectedCategoryList(selectedCategoryList);
    setSelectedSubCategoryList(selectedSubCategoryList);
    setSelectedSegmentList(selectedSegmentList);
  }

  function getFilteredBuyerFilterList() {
    // buyer always show so we just show all buyers
    let buyerList = getUniqueElementsOfArray(BuyerFilterList.map((item) => item.Buyer));

    // get departments attached to the buyers selected
    let departmentList = getUniqueElementsOfArray(
      BuyerFilterList.map((item) => ({
        Buyer: item.Buyer,
        Department: item.Department,
      }))
        .filter((item) => SelectedBuyerList.length === 0 || SelectedBuyerList.includes(item.Buyer))
        .map((item) => item.Department)
    );

    // get categories attached to the departments selected
    let categoryList = getUniqueElementsOfArray(
      BuyerFilterList.map((item) => ({
        Department: item.Department,
        Category: item.Category,
      }))
        .filter(
          (item) =>
            SelectedDepartmentList.length === 0 || SelectedDepartmentList.includes(item.Department)
        )
        .map((item) => item.Category)
    );

    // get subcategories attached to the categories selected
    let subCategoryList = getUniqueElementsOfArray(
      BuyerFilterList.map((item) => ({
        Category: item.Category,
        SubCategory: item.SubCategory,
      }))
        .filter(
          (item) =>
            SelectedCategoryList.length === 0 || SelectedCategoryList.includes(item.Category)
        )
        .map((item) => item.SubCategory)
    );

    // get segments attached to the subcategories selected
    let segmentList = getUniqueElementsOfArray(
      BuyerFilterList.map((item) => ({
        SubCategory: item.SubCategory,
        Segment: item.Segment,
      }))
        .filter(
          (item) =>
            SelectedSubCategoryList.length === 0 ||
            SelectedSubCategoryList.includes(item.SubCategory)
        )
        .map((item) => item.Segment)
    );

    setFilteredBuyerFilterList({
      Buyer: buyerList,
      Department: departmentList,
      Category: categoryList,
      SubCategory: subCategoryList,
      Segment: segmentList,
    });
  }

  function getFilteredSupplierFilterList() {
    let NewList = SupplierFilterList.filter(
      (item) =>
        (IsArrayEmpty(SelectedSupplierGroupList) ||
          SelectedSupplierGroupList.includes(item.SupplierGroup)) &&
        (IsArrayEmpty(SelectedSupplierList) || SelectedSupplierList.includes(item.Supplier))
    );

    setFilteredSupplierFilterList(NewList);
  }

  const handleClick_Apply = () => {
    setPageNo(1);
    getFilteredBuyerFilterList();
    getFilteredSupplierFilterList();
  };

  const refreshFilter = () => {
    getFilterList();

    const checkboxes = document.getElementsByClassName("form-check-input");

    for (let i = 0; i < checkboxes.length; i++) {
      const checkbox = checkboxes[i] as HTMLInputElement;
      checkbox.checked = false;
    }

    setSelectedItemPlanningList([]);
    setSelectedItemSourceList([]);
    setSelectedItemTypeList([]);
    setSelectedRangeStatusList([]);
    setSelectedMarginBandList([]);
    setSelectedPricePointList([]);
    setSelectedAgeList([]);
    setSelectedCoverStatusSOHList([]);
    setSelectedCoverStatusCommittedList([]);
    setSelectedPrivateLabelList([]);
    setSelectedSeasonalityList([]);
    setSelectedCompetitorList([]);
    setSelectedBuyerList([]);
    setSelectedDepartmentList([]);
    setSelectedCategoryList([]);
    setSelectedSubCategoryList([]);
    setSelectedSegmentList([]);
    setSelectedSupplierGroupList([]);
    setSelectedSupplierList([]);
    setSelectedManufacturerList([]);
    setSelectedItemList([]);
    setSelectedCrossCategoryList([]);
    setSelectedBuyerPicksList([]);
    setSelectedMerchandisingTraitsList([]);
    setSelectedPrivateLabelBrandList([]);
    setSelectedNPDStatusList([]);
    setSelectedNPDMarketShareList([]);
    setSelectedInstockStatusList([]);
    setSelectedSellThroughList([]);
    setSelectedRRForecastBandList([]);
    setSelectedTradingForecastBandList([]);
    setSelectedH2HSalesBandList([]);
  };

  const handleClick_Clear = () => {
    setBatchID("");
    refreshFilter();
    handleClick_Apply();
    setClearTriggered(!clearTriggered);
    setEnableCustomFilter(false);
  };

  const handleClick_CustomFilter = () => {
    setShowCustomFilter(!showCustomFilter);
  };

  const handleClick_LoadMore = () => {
    setPageNo(PageNo + 1);
  };

  const handleClick_HeaderRow = (event: MouseEvent<HTMLTableCellElement>) => {
    let _target: HTMLElement;

    switch (event.currentTarget.tagName.toUpperCase()) {
      case "TH":
        _target = event.currentTarget;
        break;
      case "SVG":
        _target = event.currentTarget.parentElement!;
        break;
      case "PATH":
        _target = event.currentTarget.parentElement!.parentElement!;
        break;
      default:
        break;
    }

    if (event.currentTarget.tagName.toUpperCase() !== "TR") {
      const _colname: string = _target!.getAttribute("data-colname")!;
      const _sortorder: string = _target!.getAttribute("data-sortorder")!;

      setSortColumn(_colname);

      if (_colname === sortColumn) {
        if (_sortorder === "ASC") {
          setSortOrder("DESC");
        } else {
          setSortOrder("ASC");
        }
      } else {
        setSortOrder("ASC");
      }
    }
  };

  // const renderItemFilters = () => {
  //   const itemFilters = [
  //     { name: "Item Planning", selectFunction: setSelectedItemPlanningList },
  //     { name: "Item Source", selectFunction: setSelectedItemSourceList },
  //     { name: "Item Type", selectFunction: setSelectedItemTypeList },
  //     { name: "Range Status", selectFunction: setSelectedRangeStatusList },
  //     { name: "Margin Band", selectFunction: setSelectedMarginBandList },
  //     { name: "Price Point", selectFunction: setSelectedPricePointList },
  //     { name: "Age", selectFunction: setSelectedAgeList },
  //     { name: "CoverStatus SOH", selectFunction: setSelectedCoverStatusSOHList },
  //     { name: "CoverStatus Committed", selectFunction: setSelectedCoverStatusCommittedList },
  //     { name: "Private Label", selectFunction: setSelectedPrivateLabelList },
  //     { name: "Seasonality", selectFunction: setSelectedSeasonalityList },
  //     { name: "Cross Category", selectFunction: setSelectedCrossCategoryList },
  //     { name: "Competitor", selectFunction: setSelectedCompetitorList },
  //     { name: "Buyer Picks", selectFunction: setSelectedBuyerPicksList },
  //     { name: "Merchandising Traits", selectFunction: setSelectedMerchandisingTraitsList },
  //     { name: "Private Label Brand", selectFunction: setSelectedPrivateLabelBrandList },
  //     { name: "NPD Status", selectFunction: setSelectedNPDStatusList },
  //     { name: "NPD Market Share", selectFunction: setSelectedNPDMarketShareList },
  //     { name: "Instock Status", selectFunction: setSelectedInstockStatusList },
  //     { name: "Sell Through", selectFunction: setSelectedSellThroughList },
  //     { name: "RR Forecast Band", selectFunction: setSelectedRRForecastBandList },
  //     { name: "Trading Forecast Band", selectFunction: setSelectedTradingForecastBandList },
  //     { name: "H2H Sales Band", selectFunction: setSelectedH2HSalesBandList },
  //   ];

  //   return (
  //     <Accordion>
  //       <Accordion.Item eventKey="0">
  //         <Accordion.Header className="text-align-center">
  //           <b>Supplier</b>
  //         </Accordion.Header>
  //         <Accordion.Body className="accordion-group-scroll">
  //           <div>
  //             {itemFilters.map((filter, index) => (
  //               <FilterAccordion
  //                 key={index}
  //                 FilterName={filter.name}
  //                 Items={getUniqueElementsOfArray(
  //                   ItemFilterList.filter(
  //                     (item) => item.FieldName === StringWithNoSpace(filter.name)
  //                   ).map((item) => item.Value)
  //                 )}
  //                 FilterItems={getUniqueElementsOfArray(
  //                   ItemFilterList.filter(
  //                     (item) => item.FieldName === StringWithNoSpace(filter.name)
  //                   ).map((item) => item.Value)
  //                 )}
  //                 OnFilterChange={(list) => onFilterItemListChange(filter.selectFunction, list)}
  //                 clearTriggered={clearTriggered}
  //                 includeSearch={filter.name === "Private Label Brand" ? true : false}
  //               ></FilterAccordion>
  //             ))}
  //           </div>
  //         </Accordion.Body>
  //       </Accordion.Item>
  //     </Accordion>
  //   );
  // };

  // const renderBuyerFilters = () => {
  //   const buyerFilters = [
  //     { name: "Buyer", selectFunction: setSelectedBuyerList },
  //     { name: "Department", selectFunction: setSelectedDepartmentList },
  //     { name: "Category", selectFunction: setSelectedCategoryList },
  //     { name: "SubCategory", selectFunction: setSelectedSubCategoryList },
  //     { name: "Segment", selectFunction: setSelectedSegmentList },
  //   ];

  //   return buyerFilters.map((filter, index) => (
  //     <FilterAccordion
  //       key={index}
  //       FilterName={filter.name}
  //       Items={getUniqueElementsOfArray(
  //         BuyerFilterList.map((item) => GetValueOfKey(item, filter.name))
  //       )}
  //       FilterItems={GetValueOfKey(FilteredBuyerFilterList, filter.name)}
  //       OnFilterChange={(list) => onFilterItemListChange(filter.selectFunction, list)}
  //       clearTriggered={clearTriggered}
  //     ></FilterAccordion>
  //   ));
  // };

  // const renderSupplierFilters = () => {
  //   const supplierFilters = [
  //     { name: "Supplier Group", selectFunction: setSelectedSupplierGroupList },
  //     { name: "Supplier", selectFunction: setSelectedSupplierList },
  //   ];

  //   return supplierFilters.map((filter, index) => (
  //     <FilterAccordion
  //       key={index}
  //       FilterName={filter.name}
  //       Items={getUniqueElementsOfArray(
  //         SupplierFilterList.map((item) => GetValueOfKey(item, StringWithNoSpace(filter.name)))
  //       )}
  //       FilterItems={getUniqueElementsOfArray(
  //         FilteredSupplierFilterList.map((item) =>
  //           GetValueOfKey(item, StringWithNoSpace(filter.name))
  //         )
  //       )}
  //       OnFilterChange={(list) => onFilterItemListChange(filter.selectFunction, list)}
  //       clearTriggered={clearTriggered}
  //     ></FilterAccordion>
  //   ));
  // };

  // const renderMiscFilters = () => {
  //   const itemFilters = [{ name: "Manufacturer", selectFunction: setSelectedManufacturerList }];

  //   return itemFilters.map((filter, index) => (
  //     <FilterAccordion
  //       key={index}
  //       FilterName={filter.name}
  //       Items={getUniqueElementsOfArray(
  //         MiscFilterList.map((item) => GetValueOfKey(item, filter.name))
  //       )}
  //       FilterItems={getUniqueElementsOfArray(
  //         MiscFilterList.map((item) => GetValueOfKey(item, filter.name))
  //       )}
  //       OnFilterChange={(list) => onFilterItemListChange(filter.selectFunction, list)}
  //       clearTriggered={clearTriggered}
  //     ></FilterAccordion>
  //   ));
  // };

  const renderBuyerFilters = () => {
    // Group Filters:
    //// Buying Manager
    //// Buyer
    //// Department
    //// Category
    //// SubCategory
    //// Segment

    const buyerFilters = [
      { name: "Buyer", selectFunction: setSelectedBuyerList, includeSearch: false },
      { name: "Department", selectFunction: setSelectedDepartmentList, includeSearch: false },
      { name: "Category", selectFunction: setSelectedCategoryList, includeSearch: true },
      { name: "SubCategory", selectFunction: setSelectedSubCategoryList, includeSearch: true },
      { name: "Segment", selectFunction: setSelectedSegmentList, includeSearch: true },
    ];

    return (
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header className="text-align-center">
            <b>Buyer</b>
          </Accordion.Header>
          <Accordion.Body className="accordion-group-scroll">
            <div>
              {/* this is for buying manager on its own */}
              <FilterAccordion
                FilterName="Buying Manager"
                Items={getUniqueElementsOfArray(
                  BuyingManagerFilterList.map((item) => GetValueOfKey(item, "BuyingManager"))
                )}
                FilterItems={getUniqueElementsOfArray(
                  BuyingManagerFilterList.map((item) => GetValueOfKey(item, "BuyingManager"))
                )}
                OnFilterChange={(list) =>
                  onFilterItemListChange(setSelectedBuyingManagerList, list)
                }
                clearTriggered={clearTriggered}
                includeSearch={false}
              ></FilterAccordion>
              {buyerFilters.map((filter, index) => (
                <FilterAccordion
                  key={index}
                  FilterName={filter.name}
                  Items={getUniqueElementsOfArray(
                    BuyerFilterList.map((item) => GetValueOfKey(item, filter.name))
                  )}
                  FilterItems={GetValueOfKey(FilteredBuyerFilterList, filter.name)}
                  OnFilterChange={(list) => onFilterItemListChange(filter.selectFunction, list)}
                  clearTriggered={clearTriggered}
                  includeSearch={filter.includeSearch}
                ></FilterAccordion>
              ))}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const renderSupplierFilters = () => {
    // Group Filters:
    //// Supplier
    //// Supplier Group
    //// Manufacturer

    const supplierFilters = [
      { name: "Supplier", selectFunction: setSelectedSupplierList, includeSearch: true },
      { name: "Supplier Group", selectFunction: setSelectedSupplierGroupList, includeSearch: true },
    ];

    return (
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header className="text-align-center">
            <b>Supplier</b>
          </Accordion.Header>
          <Accordion.Body className="accordion-group-scroll">
            <div>
              {supplierFilters.map((filter, index) => (
                <FilterAccordion
                  key={index}
                  FilterName={filter.name}
                  Items={getUniqueElementsOfArray(
                    SupplierFilterList.map((item) =>
                      GetValueOfKey(item, StringWithNoSpace(filter.name))
                    )
                  )}
                  FilterItems={getUniqueElementsOfArray(
                    FilteredSupplierFilterList.map((item) =>
                      GetValueOfKey(item, StringWithNoSpace(filter.name))
                    )
                  )}
                  OnFilterChange={(list) => onFilterItemListChange(filter.selectFunction, list)}
                  clearTriggered={clearTriggered}
                  includeSearch={filter.includeSearch}
                ></FilterAccordion>
              ))}
              {/* this is for manufacturer on its own */}
              <FilterAccordion
                FilterName="Manufacturer"
                Items={getUniqueElementsOfArray(
                  ManufacturerFilterList.map((item) => GetValueOfKey(item, "Manufacturer"))
                )}
                FilterItems={getUniqueElementsOfArray(
                  ManufacturerFilterList.map((item) => GetValueOfKey(item, "Manufacturer"))
                )}
                OnFilterChange={(list) => onFilterItemListChange(setSelectedManufacturerList, list)}
                clearTriggered={clearTriggered}
                includeSearch={true}
              ></FilterAccordion>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const renderItemFilters = () => {
    // split item filters into multiple groups
    const keyItemStatusFilter = [
      { name: "Age", selectFunction: setSelectedAgeList, includeSearch: false },
      {
        name: "Instock Status",
        selectFunction: setSelectedInstockStatusList,
        includeSearch: false,
      },
      { name: "Item Planning", selectFunction: setSelectedItemPlanningList, includeSearch: false },
      { name: "Item Source", selectFunction: setSelectedItemSourceList, includeSearch: false },
      { name: "Item Type", selectFunction: setSelectedItemTypeList, includeSearch: false },
      { name: "Margin Band", selectFunction: setSelectedMarginBandList, includeSearch: false },
      {
        name: "Merchandising Traits",
        selectFunction: setSelectedMerchandisingTraitsList,
        includeSearch: false,
      },
      { name: "Price Point", selectFunction: setSelectedPricePointList, includeSearch: false },
      { name: "Range Status", selectFunction: setSelectedRangeStatusList, includeSearch: false },
    ];

    const advancedItemStatusFilter = [
      {
        name: "RR Forecast Band",
        selectFunction: setSelectedRRForecastBandList,
        includeSearch: false,
      },
      { name: "Seasonality", selectFunction: setSelectedSeasonalityList, includeSearch: false },
      { name: "Sell Through", selectFunction: setSelectedSellThroughList, includeSearch: false },
      {
        name: "Trading Forecast Band",
        selectFunction: setSelectedTradingForecastBandList,
        includeSearch: false,
      },
    ];

    const npdFilter = [
      { name: "Competitor", selectFunction: setSelectedCompetitorList, includeSearch: false },
      {
        name: "NPD Market Share",
        selectFunction: setSelectedNPDMarketShareList,
        includeSearch: false,
      },
      { name: "NPD Status", selectFunction: setSelectedNPDStatusList, includeSearch: false },
    ];

    const privateLabelFilter = [
      { name: "Private Label", selectFunction: setSelectedPrivateLabelList, includeSearch: false },
      {
        name: "Private Label Brand",
        selectFunction: setSelectedPrivateLabelBrandList,
        includeSearch: true,
      },
    ];

    const forecastRetailFilter = [
      {
        name: "13 WK Forecast Retail",
        selectFunction: setSelected13WKForecastRetailList,
        includeSearch: false,
      },
      {
        name: "52 WK Forecast Retail",
        selectFunction: setSelected52WKForecastRetailList,
        includeSearch: false,
      },
    ];

    const forecastUnitFilter = [
      {
        name: "13 WK Forecast Unit",
        selectFunction: setSelected13WKForecastUnitList,
        includeSearch: false,
      },
      {
        name: "52 WK Forecast Unit",
        selectFunction: setSelected52WKForecastUnitList,
        includeSearch: false,
      },
    ];

    // these are unused filters, just here to keep a record, in case we need them again
    const unusedFilter = [
      {
        name: "CoverStatus SOH",
        selectFunction: setSelectedCoverStatusSOHList,
        includeSearch: false,
      },
      {
        name: "CoverStatus Committed",
        selectFunction: setSelectedCoverStatusCommittedList,
        includeSearch: false,
      },
      {
        name: "Cross Category",
        selectFunction: setSelectedCrossCategoryList,
        includeSearch: false,
      },
      { name: "Buyer Picks", selectFunction: setSelectedBuyerPicksList, includeSearch: false },
      { name: "H2H Sales Band", selectFunction: setSelectedH2HSalesBandList, includeSearch: false },
    ];

    const combinedFilter = [
      {
        FilterArr: keyItemStatusFilter,
        FilterGroupName: "Key Item Status",
      },

      {
        FilterArr: advancedItemStatusFilter,
        FilterGroupName: "Advanced Item Status",
      },
      {
        FilterArr: npdFilter,
        FilterGroupName: "NPD + Competitor Info",
      },
      {
        FilterArr: privateLabelFilter,
        FilterGroupName: "Private Label",
      },
      {
        FilterArr: forecastRetailFilter,
        FilterGroupName: "Forecast Retail",
      },
      {
        FilterArr: forecastUnitFilter,
        FilterGroupName: "Forecast Units",
      },
    ];

    return combinedFilter.map((filterArr, mainIndex) => (
      <Accordion key={mainIndex}>
        <Accordion.Item eventKey="0">
          <Accordion.Header className="text-align-center">
            <b>{filterArr.FilterGroupName}</b>
          </Accordion.Header>
          <Accordion.Body className="accordion-group-scroll">
            <div>
              {filterArr.FilterArr.map((filter, index) => (
                <FilterAccordion
                  key={index}
                  FilterName={filter.name}
                  Items={getUniqueElementsOfArray(
                    ItemFilterList.filter(
                      (item) => item.FieldName === StringWithNoSpace(filter.name)
                    ).map((item) => item.Value)
                  )}
                  FilterItems={getUniqueElementsOfArray(
                    ItemFilterList.filter(
                      (item) => item.FieldName === StringWithNoSpace(filter.name)
                    ).map((item) => item.Value)
                  )}
                  OnFilterChange={(list) => onFilterItemListChange(filter.selectFunction, list)}
                  clearTriggered={clearTriggered}
                  includeSearch={filter.includeSearch}
                ></FilterAccordion>
              ))}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    ));
  };

  const renderHeaderRow = () => {
    const headerRowArr: HeaderRowObj[] = [
      { Title: "Image", AllowSort: false },
      { Title: "Description", AllowSort: true },
      { Title: "Reorder No", AllowSort: true },
      { Title: "PO Cost", AllowSort: true },
      { Title: "Price", AllowSort: true },
      { Title: "Qty Sold L7D", AllowSort: true },
      { Title: "Committed Qty", AllowSort: true },
      { Title: "Short $ on 10W Max Units", AllowSort: true },
      { Title: "Supplier Hold Retail $", AllowSort: true },
    ];

    return headerRowArr.map((headerObj, index) => {
      const headerTitleNoSpace: string = StringWithNoSpace(headerObj.Title);
      return (
        <th
          key={index}
          data-colname={headerTitleNoSpace}
          // data-sortorder={sortOrder}
          // onClick={headerObj.AllowSort ? handleClick_HeaderRow : undefined}
        >
          {headerObj.Title}
          {/* {headerObj.AllowSort && (
            <> {sortColumn === headerTitleNoSpace ? <FontAwesomeIcon icon={sortIcon} /> : ""}</>
          )} */}
        </th>
      );
    });
  };

  const renderSort = () => {
    const sortArr = [
      { Title: "Supplier Account", SortColumn: "SupplierAccount", SortOrder: "ASC" },
      { Title: "Sell Price: Low to High", SortColumn: "SellPrice", SortOrder: "ASC" },
      { Title: "Sell Price: High to Low", SortColumn: "SellPrice", SortOrder: "DESC" },
      { Title: "Last PO Cost", SortColumn: "LastPOCost", SortOrder: "ASC" },
      { Title: "Cost Price", SortColumn: "CostPrice", SortOrder: "ASC" },
      { Title: "Qty Sold L7D", SortColumn: "QtySoldL7D", SortOrder: "DESC" },
      { Title: "Qty Sold L52W", SortColumn: "QtySoldL52W", SortOrder: "DESC" },
      { Title: "Sold$ LW", SortColumn: "SoldLWD", SortOrder: "DESC" },
      { Title: "Sold$ LM", SortColumn: "SoldLMD", SortOrder: "DESC" },
      { Title: "Sold$ YTD", SortColumn: "SoldYTD", SortOrder: "DESC" },
      { Title: "Sold$ L52W", SortColumn: "SoldL52W", SortOrder: "DESC" },
      { Title: "Sold$ LY H2H", SortColumn: "LYH2H", SortOrder: "DESC" },
      { Title: "RR Forecast Retail", SortColumn: "RRForecastRetail", SortOrder: "DESC" },
      { Title: "Trading Forecast Retail", SortColumn: "TradingForecastRetail", SortOrder: "DESC" },
      { Title: "52W Forecast Retail", SortColumn: "52WForecastRetail", SortOrder: "DESC" },
      { Title: "RR Forecast: Low to High", SortColumn: "RRForecast", SortOrder: "ASC" },
      { Title: "RR Forecast: High to Low", SortColumn: "RRForecast", SortOrder: "DESC" },
      { Title: "Supplier Hold Retail", SortColumn: "SupplierHoldRetail", SortOrder: "DESC" },
      { Title: "Committed SOH $", SortColumn: "CommittedRetail", SortOrder: "DESC" },
      { Title: "Max Instock% L4W: Low to High", SortColumn: "MaxInstockL4W", SortOrder: "ASC" },
      { Title: "Max Instock% L4W: High to Low", SortColumn: "MaxInstockL4W", SortOrder: "DESC" },
      { Title: "Committed WOC: Low to High", SortColumn: "CommittedWOC", SortOrder: "ASC" },
      { Title: "Committed WOC: High to Low", SortColumn: "CommittedWOC", SortOrder: "DESC" },
    ];

    return sortArr.map((sortObj, index) => (
      <li
        key={index}
        className={`${
          sortObj.SortColumn + "-" + sortObj.SortOrder === sortColumn + "-" + sortOrder &&
          "selected"
        } btnHover`}
        data-sortcolumn={sortObj.SortColumn}
        data-sortorder={sortObj.SortOrder}
        onClick={() => {
          setSortColumn(sortObj.SortColumn);
          setSortOrder(sortObj.SortOrder);
          setShowSort(false); // hide sort offcanvas after selecting
        }}
      >
        {sortObj.Title}
      </li>
    ));
  };

  const renderItems = () => {
    let ProductDisplay;

    switch (ProductView) {
      case "list":
        ProductDisplay = (
          <div className="grid-container">
            <table className="itemlist-table">
              <thead className="sticky">
                <tr className="btnHover">{renderHeaderRow()}</tr>
              </thead>
              <tbody>
                {SourcingItemList.map((item, index) => (
                  <tr
                    key={"tablerow-" + index}
                    className="btnHover"
                    onClick={() => {
                      window.open(`/DetailsPage/${item.ItemNo}`, "_blank");
                    }}
                  >
                    <td width={200}>
                      {item.ImgURL.length === 0 ? (
                        <img
                          className="list-image"
                          loading="lazy"
                          src={NoImgAvail}
                          alt="No image available"
                        />
                      ) : (
                        <img
                          className="list-image"
                          loading="lazy"
                          src={item.ImgURL}
                          alt="No image available"
                        />
                      )}
                    </td>
                    <td width={250}>{item.Description}</td>
                    <td width={150}>{item.ReorderNumber}</td>
                    <td width={100}>{ConvertToCurrency(item.LastPOCost)}</td>
                    <td width={100}>{ConvertToCurrency(item.Price)}</td>
                    <td width={120}>{item.QtySoldL7D}</td>
                    <td width={130}>{item.CommittedQty}</td>
                    <td width={220}>{ConvertToCurrency(item.ShortMaxUnits, 0)}</td>
                    <td width={200}>{ConvertToCurrency(item.SupplierHoldRetail, 0)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
        break;
      case "large":
        ProductDisplay = (
          <div className="card-flexbox">
            {SourcingItemList.map((item, index) => (
              <div
                key={index}
                className={`card-large-wrapper ${
                  SelectedItemList.includes(item.ItemNo) ? "selected" : ""
                }`}
                onClick={() => {
                  toggleCardSelect(item.ItemNo);
                }}
              >
                <ItemCardLarge
                  key={index}
                  {...item}
                  Mode={Mode}
                  IsSelected={SelectedItemList.includes(item.ItemNo)}
                  ToggleCardSelect={toggleCardSelect}
                  PriceChangeType=" "
                  NewSellPrice={0}
                  BuyerComment=" "
                  Approved={0}
                  DateJSON=""
                  WeekOfYear={0}
                />
              </div>
            ))}
          </div>
        );

        break;
      case "small":
        ProductDisplay = (
          <div className="card-flexbox">
            {SourcingItemList.map((item, index) => (
              <div
                className={`card-small-wrapper ${
                  SelectedItemList.includes(item.ItemNo) ? "selected" : ""
                }`}
                onClick={() => {
                  toggleCardSelect(item.ItemNo);
                }}
              >
                <ItemCardSmall key={index} {...item} />
              </div>
            ))}
          </div>
        );
        break;
    }
    return ProductDisplay;
  };

  const handleClick_Search = () => {
    setShowSearch(!showSearch);
  };

  const handleClick_Filter = () => {
    setShowFilter(true);
  };

  const handleClick_Sort = () => {
    setShowSort(true);
  };

  const handleClick_Summary = () => {
    if (SelectedItemList.length !== 0) {
      setShowSummary(true);
    }
  };

  const handleClick_ProductView = () => {
    setShowProductView(true);
  };

  const handleClick_SelectAll = () => {
    const arrAllItems: string[] = SourcingItemList.map((item) => item.ItemNo);
    setSelectedItemList(arrAllItems);
  };

  const handleClick_SelectNone = () => {
    setSelectedItemList([]);
  };

  const toggleCardSelect = (itemno: string) => {
    // if itemno is in selected list

    let nextSelectedItemList: string[] = SelectedItemList;
    if (SelectedItemList.includes(itemno)) {
      nextSelectedItemList = nextSelectedItemList.filter((item) => item !== itemno);
    } else {
      nextSelectedItemList = nextSelectedItemList.concat(itemno);
    }

    setSelectedItemList(nextSelectedItemList);
  };

  useEffect(() => {
    refreshFilter();
  }, []);

  useEffect(() => {
    switch (sortOrder) {
      case "ASC":
        setSortIcon(solid("sort-up"));
        break;
      case "DESC":
        setSortIcon(solid("sort-down"));
        break;
      default:
        setSortIcon(solid("sort"));
        break;
    }
  }, [sortOrder]);

  useEffect(() => {
    // resolve selected department (get rid of departments that are not )
  }, [SelectedBuyerList]);

  useEffect(() => {
    // resolve selected category
  }, [SelectedDepartmentList]);

  useEffect(() => {
    // resolve selected subcategory
  }, [SelectedCategoryList]);

  useEffect(() => {
    // resolve selected segment
  }, [SelectedSubCategoryList]);

  useEffect(() => {
    getFilteredBuyerFilterList();
    getFilteredSupplierFilterList();
  }, [
    SelectedItemPlanningList,
    SelectedItemSourceList,
    SelectedItemTypeList,
    SelectedRangeStatusList,
    SelectedMarginBandList,
    SelectedPricePointList,
    SelectedAgeList,
    SelectedCoverStatusSOHList,
    SelectedCoverStatusCommittedList,
    SelectedPrivateLabelList,
    SelectedSeasonalityList,
    SelectedCrossCategoryList,
    SelectedCompetitorList,
    SelectedBuyerPicksList,
    SelectedMerchandisingTraitsList,
    SelectedPrivateLabelBrandList,
    SelectedNPDStatusList,
    SelectedNPDMarketShareList,
    SelectedInstockStatusList,
    SelectedSellThroughList,
    SelectedRRForecastBandList,
    SelectedTradingForecastBandList,
    SelectedH2HSalesBandList,
    SelectedBuyerList,
    SelectedDepartmentList,
    SelectedCategoryList,
    SelectedSubCategoryList,
    SelectedSegmentList,
    SelectedSupplierGroupList,
    SelectedSupplierList,
  ]);

  useEffect(() => {
    setSelectedItemList([]);
  }, [ProductView]);

  useEffect(() => {
    if (!showFilter && !showSort) {
      getItems();
      setSelectedItemList([]);
    }
  }, [showSort, showFilter, SearchDescription, PageNo, sortColumn, sortOrder, batchID]);

  return (
    <div>
      <Header
        showSearch={showSearch}
        HeaderSource={"PLP"}
        ProductView={ProductView}
        setProductView={setProductView}
        setSearchDescription={setSearchDescription}
        handleClick_Search={handleClick_Search}
        handleClick_Filter={handleClick_Filter}
        handleClick_Sort={handleClick_Sort}
        handleClick_AddItem={() => {}}
        handleClick_Approve={() => {}}
        handleClick_Process={() => {}}
        handleClick_BuyerSummary={() => {}}
        handleClick_Summary={handleClick_Summary}
        handleClick_PlanningReport={() => {}}
        handleClick_SelectAll={handleClick_SelectAll}
        handleClick_SelectNone={handleClick_SelectNone}
        handleClick_ImportPricing={() => {}}
        handleClick_ImportBuyerQuantities={() => {}}
        handleClick_ImportPlannerQuantities={() => {}}
        handleClick_ImportPrePOQuantities={() => {}}
      />
      <div>
        {IsGridLoading ? (
          <Spinner className="spinner" animation="border" />
        ) : (
          <>
            {renderItems()}
            <div className="default-alignment">
              <Button variant="primary" onClick={handleClick_LoadMore}>
                Load More
              </Button>
            </div>
          </>
        )}

        <MyOffcanvas
          show={showFilter}
          setShow={setShowFilter}
          OffcanvasTitle="All filters"
          OffcanvasComponent={
            <>
              <div className="offcanvas-button-container">
                <div className="offcanvas-filter-clearall btnHover" onClick={handleClick_Clear}>
                  Clear All
                </div>
                {enableCustomFilter == false && (
                  <>
                    <div
                      className="offcanvas-filter-clearall btnHover"
                      onClick={handleClick_CustomFilter}
                    >
                      Custom Filter
                    </div>
                  </>
                )}
              </div>
              {enableCustomFilter == false && (
                <>
                  {renderBuyerFilters()}
                  {renderSupplierFilters()}
                  {renderItemFilters()}
                </>
              )}
            </>
          }
        />

        <MyOffcanvas
          show={showSort}
          setShow={setShowSort}
          OffcanvasTitle="Sort"
          OffcanvasComponent={<AdvancedSortComponent />}
        />
      </div>

      <SummaryPopup
        inputJSON={JSON.stringify([{ ItemNo: SelectedItemList }])}
        show={showSummary}
        setShow={setShowSummary}
      />
      <CustomFilterPopup
        show={showCustomFilter}
        action="Apply"
        setShow={setShowCustomFilter}
        doLookup={(batchNo) => {
          setBatchID(batchNo);
          setEnableCustomFilter(true);
        }}
      />
    </div>
  );
}

export default ItemPLP;
