import React, { ChangeEvent, SetStateAction, useEffect, useRef, useState } from "react";
import { SortItem } from "../pages/ItemPLP/ItemPLP";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  arraySwap,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import AdvancedSortableItem from "./AdvancedSortableItem";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import store from "../store";

function AdvancedSortComponent() {
  const sortArr = [
    { id: 1, SortTitle: "Supplier Account", SortType: "SupplierAccount" },
    { id: 2, SortTitle: "Sell Price", SortType: "SellPrice" },
    { id: 3, SortTitle: "Last PO Cost", SortType: "LastPOCost" },
    { id: 4, SortTitle: "Cost Price", SortType: "CostPrice" },
    { id: 5, SortTitle: "Qty Sold L1D", SortType: "QtySoldL1D" },
    { id: 6, SortTitle: "Qty Sold L7D", SortType: "QtySoldL7D" },
    { id: 7, SortTitle: "Qty Sold L52D", SortType: "QtySoldL52D" },
    { id: 8, SortTitle: "Qty Sold LY H2H", SortType: "QtySoldLYH2H" },
    { id: 9, SortTitle: "Sold$ L1D", SortType: "SoldL1D" },
    { id: 10, SortTitle: "Sold$ LW", SortType: "SoldLWD" },
    { id: 11, SortTitle: "Sold$ LM", SortType: "SoldLMD" },
    { id: 12, SortTitle: "Sold$ YTD", SortType: "SoldYTD" },
    { id: 13, SortTitle: "Sold$ L52W", SortType: "SoldL52W" },
    { id: 14, SortTitle: "Sold$ LY H2H", SortType: "SoldLYH2H" },
    // { id: 15, SortTitle: "Sold$ Cost", SortType: "SoldCost" },
    { id: 16, SortTitle: "Sold$ Retail", SortType: "SoldRetail" },
    { id: 17, SortTitle: "RR Forecast Retail", SortType: "RRForecastRetail" },
    { id: 18, SortTitle: "Trading Forecast Retail", SortType: "TradingForecastRetail" },
    { id: 19, SortTitle: "13W Forecast Retail", SortType: "13WForecastRetail" },
    { id: 20, SortTitle: "52W Forecast Retail", SortType: "52WForecastRetail" },
    { id: 21, SortTitle: "RR Forecast", SortType: "RRForecast" },
    // { id: 22, SortTitle: "Buyer Hold Retail", SortType: "BuyerHoldRetail" },
    { id: 23, SortTitle: "Supplier Hold Retail", SortType: "SupplierHoldRetail" },
    { id: 25, SortTitle: "Committed Retail $", SortType: "CommittedRetail" },
    { id: 26, SortTitle: "Max Instock% L4W", SortType: "MaxInstockL4W" },
    { id: 27, SortTitle: "Committed Units", SortType: "CommittedUnits" },
    { id: 28, SortTitle: "SOH WOC", SortType: "SOHWOC" },
    { id: 29, SortTitle: "Committed WOC", SortType: "CommittedWOC" },
  ];

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const emptySortObj: SortItem = {
    id: 0,
    SortTitle: "",
    SortType: "",
    SortOrder: "",
  };
  const [SelectedSortObj, setSelectedSortObj] = useState<SortItem>(emptySortObj);

  const [currentSortItems, setCurrentSortItems] = useState<SortItem[]>([]);

  const [AdvancedSortItems, setAdvancedSortItems] = store.useState("advancedSortItems");

  const refSortTitle = useRef<HTMLSelectElement>(null);
  const refSortOrder = useRef<HTMLSelectElement>(null);

  const handleChange_SortTitle = (e: ChangeEvent<HTMLSelectElement>) => {
    let newSortObj: SortItem = SelectedSortObj;

    setSelectedSortObj({
      ...newSortObj,
      SortType: e.target.value,
    });
  };

  const handleChange_SortOrder = (e: ChangeEvent<HTMLSelectElement>) => {
    let newSortObj: SortItem = SelectedSortObj;

    setSelectedSortObj({
      ...newSortObj,
      SortOrder: e.target.value,
    });
  };

  const handleClick_AddSort = () => {
    if (SelectedSortObj.SortType === "" || SelectedSortObj.SortOrder === "") return;

    const _currentSortItemCount = currentSortItems.length;

    setCurrentSortItems([
      ...currentSortItems,
      {
        ...SelectedSortObj,
        id: _currentSortItemCount,
      },
    ]);

    setSelectedSortObj(emptySortObj);

    if (refSortTitle.current) refSortTitle.current.value = "";
    if (refSortOrder.current) refSortOrder.current.value = "";
  };

  const handleClick_DeleteSort = (_id: number) => {
    // we need to reset id for useSortable to prevent id duplication when we add more sort items after deleting
    setCurrentSortItems(
      currentSortItems
        .filter((item) => item.id !== _id)
        .map((item, index) => ({
          ...item,
          id: index,
        }))
    );
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over) return;

    if (active.id !== over?.id) {
      setCurrentSortItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  useEffect(() => {
    setAdvancedSortItems(
      JSON.stringify(
        currentSortItems.map((item, index) => ({
          SortType: item.SortType,
          SortOrder: item.SortOrder,
          Order: index,
        }))
      )
    );
  }, [currentSortItems]);

  return (
    <ul className="sort-list-container">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={currentSortItems}
          strategy={verticalListSortingStrategy}
        >
          {currentSortItems.map((id) => {
            let foundItem = sortArr.find((item) => item.SortType === id.SortType);

            return (
              <AdvancedSortableItem
                key={id.id}
                id={id.id}
                content={`${foundItem?.SortTitle} - ${id.SortOrder}`}
                deleteSort={() => handleClick_DeleteSort(id.id)}
              />
            );
          })}
        </SortableContext>
      </DndContext>
      <div className="sort-item-container add-sort-item">
        <li className="add-sort">
          <Form.Select
            onChange={handleChange_SortTitle}
            ref={refSortTitle}
            className="no-highlight"
          >
            <option value=""></option>
            {sortArr
              .filter(
                (item) => !currentSortItems.map((item) => item.SortType).includes(item.SortType)
              )
              .map((item, index) => (
                <option
                  key={index}
                  value={item.SortType}
                >
                  {item.SortTitle}
                </option>
              ))}
          </Form.Select>
          <Form.Select
            onChange={handleChange_SortOrder}
            ref={refSortOrder}
            className="no-highlight"
          >
            <option value=""></option>
            <option value="ASC">ASC</option>
            <option value="DESC">DESC</option>
          </Form.Select>
          <FontAwesomeIcon
            icon={solid("plus")}
            onClick={handleClick_AddSort}
            className="btnHover"
          />
        </li>
      </div>
    </ul>
  );
}

export default AdvancedSortComponent;
