import { Card, Row } from "react-bootstrap";
import { ConvertToCurrency } from "../functions/ConvertToCurrency";
import { ConvertToPercent } from "../functions/ConvertToPercent";
import { getReqOpt_POST, runFetch, spURL_POST } from "../functions/RunFetch";
import NoImgAvail from "../assets/img/NoImgAvailable.jpg";
import { useState, useEffect, PropsWithoutRef } from "react";
import CommentPopup from "./PopupComment";
import { PriceChangeItemObj } from "../pages/PriceChange/PriceChange";
import { StringWithNoSpace } from "../functions/StringWithNoSpace";

interface ItemCardPriceChangeProps {
  ItemNo: string;
  ImgURL: string;
  Description: string;
  SupplierAccount: string;
  Manufacturer: string;
  ReorderNumber: string;
  ItemType: string;
  RangeStatus: string;
  AvgCost: number;
  LastPOCost: number;
  Price: number;
  ForeignCost: number;
  MSRP: number;
  CloseoutPrice: number;
  MarginP: number;
  Age: string;
  SOHQty: number;
  CommittedQty: number;
  SOTWH: number;
  SOHStoreQty: number;
  SoldLWQty: number;
  SoldLWRetail: number;
  SoldLMQty: number;
  SoldLMRetail: number;
  SoldYTDQty: number;
  SoldYTDRetail: number;
  MaxInstockL4W: number;
  SOHWOC: number;
  CommittedWOC: number;
  LeadCompName: string;
  LeadCompPrice: number;
  IsSelected: boolean;
  PriceChangeType: string;
  NewSellPrice: number;
  BuyerComment: any;
  Approved: number;
  DateJSON: any;
  WeekOfYear: number;
  PreviousPrice: number;
  PreviousPreviousPrice: number;
  ToggleCardSelect: (itemno: string) => void;
  ChangeNewSellPrice: (itemno: string, newprice: number) => void;
  ChangeDate: (itemno: string, week: number) => void;
  RemoveComment: (itemno: string) => void;
  UpdatePriceChangeItemList: (itemno: string, newbuyercomment: string) => void;
}

function ItemCardPriceChange({
  ItemNo,
  ImgURL,
  Description,
  SupplierAccount,
  Manufacturer,
  ReorderNumber,
  ItemType,
  RangeStatus,
  AvgCost,
  LastPOCost,
  Price,
  ForeignCost,
  MSRP,
  CloseoutPrice,
  MarginP,
  Age,
  SOHQty,
  CommittedQty,
  SOTWH,
  SOHStoreQty,
  SoldLWQty,
  SoldLWRetail,
  SoldLMQty,
  SoldLMRetail,
  SoldYTDQty,
  SoldYTDRetail,
  MaxInstockL4W,
  SOHWOC,
  CommittedWOC,
  LeadCompName,
  LeadCompPrice,
  PriceChangeType,
  NewSellPrice,
  BuyerComment,
  Approved,
  IsSelected,
  DateJSON,
  WeekOfYear,
  PreviousPrice,
  PreviousPreviousPrice,
  ToggleCardSelect,
  ChangeNewSellPrice,
  ChangeDate,
  RemoveComment,
  UpdatePriceChangeItemList,
}: ItemCardPriceChangeProps) {
  const [showComments, setShowComments] = useState<boolean>(false);

  const renderCardData = () => {
    let componentCard = (
      <div className="card-large-container">
        <div className="card-large-title">
          <div>
            <p className="color-forestgreen">
              {ConvertToPercent((NewSellPrice / 1.1 - AvgCost) / (NewSellPrice / 1.1))}
            </p>
            <p>{Description}</p>
            <p>{ItemType}</p>
            <p>{RangeStatus}</p>
            <p>{ItemNo}</p>
            <div>
              <select
                className="week-selector"
                defaultValue={WeekOfYear}
                onChange={(e) => ChangeDate(ItemNo, parseFloat(e.target.value))}
              >
                {DateJSON.map((weekOption: string, index: number) => (
                  <option key={index} value={weekOption}>
                    {weekOption}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div>
            <>
              <strong>$</strong>
              <input
                type="text"
                name="NewPrice"
                className="new-price-input"
                defaultValue={NewSellPrice}
                onBlur={(e) => ChangeNewSellPrice(ItemNo, parseFloat(e.target.value))}
              />
            </>
            <p className="price">{ConvertToCurrency(Price)}</p>
            <p className="item-number">
              <span>
                LPO:{ConvertToCurrency(LastPOCost)} AVG:{ConvertToCurrency(AvgCost)}{" "}
                {ForeignCost === 0 ? "" : "USD:" + ConvertToCurrency(ForeignCost)}{" "}
              </span>
              <span>
                {MSRP === 0 ? "" : "MSRP:" + ConvertToCurrency(MSRP)}{" "}
                {CloseoutPrice === 0 || CloseoutPrice === Price
                  ? ""
                  : "CS:" + ConvertToCurrency(CloseoutPrice)}
              </span>
            </p>
            <p className="color-dodgerblue">{MarginP}%</p>
            <p>
              {PreviousPrice === 0 ? "" : "PP:" + ConvertToCurrency(PreviousPrice)}{" "}
              {PreviousPreviousPrice === 0 ? "" : "PPP:" + ConvertToCurrency(PreviousPreviousPrice)}{" "}
            </p>
            <div>
              <p></p>
            </div>
          </div>
        </div>
        <div className="card-large-body">
          <div className="card-large-body-row-table">
            <div className="card-large-body-column">
              <div>
                <p>{ReorderNumber}</p>
              </div>
              <div>
                <p>{Age}</p>
              </div>
              <div
                style={{
                  color: MaxInstockL4W < 70 ? "Red" : "#262626",
                }}
              >
                <p>L4W Instock</p>
                <p>{MaxInstockL4W}%</p>
              </div>
            </div>
            <div>
              <div
                style={{
                  color: PriceChangeType.includes("Mark Down") ? "Red" : "DodgerBlue",
                }}
              >
                <p>{PriceChangeType}</p>
              </div>
              <table className="card-large-table align-right">
                <tbody>
                  <tr>
                    <td>
                      <p>LW</p>
                    </td>
                    <td>
                      <p>{SoldLWQty}</p>
                    </td>
                    <td>
                      <p>{ConvertToCurrency(SoldLWRetail, 0)}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>LM</p>
                    </td>
                    <td>
                      <p>{SoldLMQty}</p>
                    </td>
                    <td>
                      <p>{ConvertToCurrency(SoldLMRetail, 0)}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>YTD</p>
                    </td>
                    <td>
                      <p>{SoldYTDQty}</p>
                    </td>
                    <td>
                      <p>{ConvertToCurrency(SoldYTDRetail, 0)}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="margin-5"></div>
          <div className="card-large-body-row">
            <div>
              <table className="card-large-table-SOH">
                <tbody>
                  <tr>
                    <td>
                      <p>SOH</p>
                    </td>
                    <td>
                      <p>{SOHQty}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Cmt</p>
                    </td>
                    <td>
                      <p>{CommittedQty}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Instore</p>
                    </td>
                    <td>
                      <p>{SOHStoreQty}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Xfer</p>
                    </td>
                    <td>
                      <p>{SOTWH}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div>
              <div
                style={{
                  color: SOHWOC > 52 ? "Red" : SOHWOC <= 6 ? "DodgerBlue" : "#262626",
                }}
              >
                <p>WOC SOH</p>
                <p>{SOHWOC}</p>
              </div>
              <div
                style={{
                  color: CommittedWOC > 52 ? "Red" : CommittedWOC <= 6 ? "DodgerBlue" : "#262626",
                }}
              >
                <p>WOC Cmt</p>
                <p>{CommittedWOC}</p>
              </div>
            </div>
          </div>
          <div className="margin-5"></div>
          <div className="card-large-body-row">
            <span className="span-5050">{SupplierAccount}</span>
            <span className="span-5050">{Manufacturer}</span>
          </div>
          <div className="card-large-body-row">
            <div>
              <p>{LeadCompName}</p>
            </div>
            <div>
              <p>{LeadCompPrice === 0 ? "" : ConvertToCurrency(LeadCompPrice, 2)}</p>
            </div>
          </div>
          <div className="card-large-body-row">
            <button className="comment-button" onClick={() => setShowComments(true)}>
              +
            </button>
            <p className="comment-title">Comments</p>
            <button
              className="comment-button"
              onClick={() => {
                RemoveComment(ItemNo);
              }}
            >
              -
            </button>
          </div>
          {BuyerComment.length > 0 && (
            <table>
              <tbody>
                {BuyerComment.map((comment: string, index: number) => (
                  <tr key={index} className="card-large-footer-table">
                    <td>{comment}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <div></div>
        </div>

        <CommentPopup
          show={showComments}
          setShow={setShowComments}
          itemNo={ItemNo}
          UpdatePriceChangeItemList={UpdatePriceChangeItemList}
        />
      </div>
    );

    return componentCard;
  };

  return (
    // <div
    //   className={`card-large-wrapper${IsSelected ? "-selected" : ""}`}
    //   onClick={handleClick_Card}
    // >
    <>
      <div className="card-large-image-container default-alignment padding-0">
        {ImgURL.length === 0 ? (
          <img
            className="card-large-image"
            loading="lazy"
            src={NoImgAvail}
            alt="No image available"
          ></img>
        ) : (
          <img className="card-large-image" loading="lazy" src={ImgURL} alt="No image available" />
        )}
        <input
          type="checkbox"
          className="card-check"
          checked={IsSelected}
          onChange={() => ToggleCardSelect(ItemNo)}
        />
      </div>
      {renderCardData()}
    </>
    // </div>
  );
}
export default ItemCardPriceChange;
